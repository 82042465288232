import React, { useState, Dispatch, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import Button from '../../components/Button';

import Pointer from '../Pointer';

interface Localizacao {
  lat: any;
  lng: any;
}

interface Props {
  localizacao: Localizacao;
  setLocalizacao: Dispatch<Localizacao>;
}

interface Maps {
  maps: {
    map: any;
    maps: any;
    ref: Element | null;
  }
}



const SimpleMap: React.FC<Props> = ({ localizacao, setLocalizacao }) => {
  const [center, setCenter] = useState({
    lat: -16.689556,
    lng: -49.2633181
  });

  const [map, setMap] = useState();
  const [maps, setMaps] = useState({} as any)

  const [triangleCoords, setTriangleCoords] = useState<Localizacao[]>([]
  );

  const [polylines, setPolylines] = useState<any[]>([]);

  function handleAddTriangleCoords(coords?: Localizacao) {

    if (maps && map) {
      let coord: Localizacao[] = [];
      if (coords) {
        coord = [
          ...triangleCoords,
          coords
        ]

        setTriangleCoords(coord);
      } else {
        coord = [
          ...triangleCoords
        ]
      }



      polylines.forEach(p => {
        p.setMap(null);
      })


      let nonGeodesicPolyline = new maps.Polyline({
        path: coord,
        geodesic: false,
        strokeColor: '#FF0000',
        strokeOpacity: 0.7,
        strokeWeight: 3,
        fillColor: "#FF0000",
        fillOpacity: 0.35
      })


      nonGeodesicPolyline.setMap(map);
      setPolylines([nonGeodesicPolyline]);

    }
  }


  const [zoom, setZoom] = useState(14);


  function handleApiLoaded(map: any, maps: any) {

    /*var bermudaTriangle = new maps.Polygon({
      paths: triangleCoords,
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35
    });*/

    setMap(map);
    setMaps(maps);

    //bermudaTriangle.setMap(map);
  }

  // function handleClick(lat: any, lng: any) {
  //   handleAddTriangleCoords({ lat, lng });
  // }

  const handleClickPoint = (lat: number, lng: number) => {
    handleAddTriangleCoords({
      lat, lng
    })
  }

  const RemovePointAnterior = () => {
    const novo = triangleCoords;

   ;
    setTriangleCoords(novo);
    handleAddTriangleCoords(undefined);
  }

  const RemoveAllPoints = () => {
    setTriangleCoords([])
    handleAddTriangleCoords(undefined)
  }

  return (
    <div style={{ height: '50vh', width: '100%' }}>

      <div style={{ display: 'flex', marginBottom: '12px', justifyContent: 'space-between'}}>
        <Button onClick={RemoveAllPoints} style={{ marginRight: '4px'}}> Remover todos pontos </Button>
        <Button onClick={RemovePointAnterior} style={{ marginLeft: '4px'}}> Remover ponto anterior</Button>
      </div>

      <GoogleMapReact
        bootstrapURLKeys={{ key: '' }}

        defaultCenter={center}
        defaultZoom={zoom}
        onClick={({ lat, lng }) => handleAddTriangleCoords({ lat, lng })}
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
      >
        {triangleCoords.map((p: Localizacao) =>
          <Pointer
            lat={p.lat}
            lng={p.lng}
            name="My Marker"
            onClick={handleClickPoint}
          />
        )}

      </GoogleMapReact>
    </div>
  );
}

export default SimpleMap;
