import * as Yup from 'yup';

const schema = Yup.object().shape({
    nome: Yup.string()
        .min(3)
        .max(100)
        .required('Nome é Obrigatório'),
    email: Yup.string().email("Digite um Email válido")
        .required('E-mail é Obrigatório'),
    senha: Yup.string()
        .min(3, 'mínimo 3 caracteres')
        .max(10, 'max 10 caracteres'),
});

export default schema;