import React, { Dispatch, useState, useEffect, ChangeEvent } from 'react';
import { TTable, Thead, Tbody, Content, Action, Ativo, Inativo } from './style';
import { FiEdit, FiTrash, FiMoreVertical, FiChevronDown, FiChevronUp } from 'react-icons/fi';
import api from '../../services/api';
import TableConfig from '../../components/TableConfig';


interface RowProps {
    record: any;
    edit(number: Number): void;
    remove(number: Number): void;
    camposVisualizar: any[];
}

interface HeadProps {
    setOrderBy: Dispatch<string>;
    setShowModalConfig: Dispatch<boolean>;
    handleTipoOrder(): void;
    keys: any;
    head: any;
    handleInputChange?(event: ChangeEvent<HTMLInputElement>): void;
}

const Head: React.FC<HeadProps> = ({ keys, head, setOrderBy, handleTipoOrder, setShowModalConfig, handleInputChange }) => {
    const tableHead = head || {};
    const [clicou, setClicou] = useState<boolean>(false);


    return (
        <Thead>
            <tr>
                {
                    keys.map((key: any, index: any) =>
                        tableHead[key] != 'Ativo' ? (
                            <th key={key} className={index > 5 ? "ocultar" : ""} >
                                <div>
                                    <input id={key} name={key} placeholder={tableHead[key] || key}
                                        style={key == 'id' ? { width: '40px' , textTransform: 'uppercase'  } : {  textTransform: 'uppercase'  }}
                                        onChange={handleInputChange}
                                        autoComplete="off"
                                        />
                                    {clicou ? <FiChevronDown size={16} onClick={() => { setOrderBy(key); handleTipoOrder(); setClicou(!clicou); }} />
                                        : <FiChevronUp size={16} onClick={() => { setOrderBy(key); handleTipoOrder(); setClicou(!clicou); }} />}
                                </div>
                            </th>
                        ) :
                            (
                                <th onClick={() => { setOrderBy(key); handleTipoOrder(); }} key={key} className={index > 5 ? "ocultar" : ""} >
                                    {tableHead[key] || key}
                                </th>
                            )
                    )
                }

                <th>
                    <div style={{ display: 'flex', padding: 0, justifyContent: 'flex-end', cursor: 'pointer' }}>
                        <FiMoreVertical onClick={() => setShowModalConfig(true)} size={18} />
                    </div>
                </th>
            </tr>


        </Thead>
    )
}

const Row: React.FC<RowProps> = ({ record, edit, remove, camposVisualizar, ...rest }) => {

    const keys = camposVisualizar ? camposVisualizar : Object.keys(record);

    return (
        <Tbody>
            {/* <tr>

                {
                    keys.map((key: any) =>
                        <td key={key} >
                            <Input name={key} id={key} placeholder={key}  />
                        </td>
                    )
                }
                  <td style={{ backgroundColor: '#fff'}}>
                  </td>
            </tr> */}


            <tr key={record.id}>
                {
                    keys.map((key, index) =>
                        <td key={key} className={index > 5 ? "ocultar" : ""}>
                            {typeof record[key] === 'boolean' ? record[key] ? <Ativo /> : <Inativo /> : record[key]}
                        </td>
                    )
                }

                <td>
                    <Action>
                        <button onClick={() => { edit(record) }}>
                            <FiEdit size={17} />
                        </button>
                        <button onClick={() => { remove(record) }}>
                            <FiTrash size={17} />
                        </button>
                    </Action>
                </td>
            </tr>
        </Tbody>
    )
}

interface TableProps {
    data: any[];
    head: any;
    loading: boolean;
    numeroTela: string;
    edit(number: Number): void;
    remove(number: Number): void;
    setOrderBy: Dispatch<string>;
    handleTipoOrder(): void;
    handleInputChange?(event: ChangeEvent<HTMLInputElement>): void;
}

const Table: React.FC<TableProps> = ({
    data,
    head,
    loading,
    edit,
    remove,
    numeroTela,
    setOrderBy,
    handleTipoOrder,
    handleInputChange,
    ...rest
}) => {

    const [showModalConfig, setShowModalConfig] = useState(false);
    const [camposNaoPermitidos, setCamposNaoPermitidos] = useState<string[]>(Object.keys(head));

    const initial = Object.keys(head);

    useEffect(() => {
        const Pesquisar = async () => {
            const res = await api.get('/configs', {
                params: {
                    chave: numeroTela,
                } 
            });
            if (res.data && res.data.config) {
                const config = res.data.config;
                if (config.camposPermitidos) {
                    setCamposNaoPermitidos(config.camposPermitidos);
                }
            }
        }
        Pesquisar();
    }, []);

    const handleSetCampos = async (campos: string[]) => {
        await api.post('/configs', {
            chave: numeroTela,
            config: {
                camposPermitidos: campos
            }
        });
        setCamposNaoPermitidos(campos);
    }

    const keys = camposNaoPermitidos ? camposNaoPermitidos : Object.keys(data[0]);

    return (
        <>

            <TableConfig
                campos={initial}
                head={head}
                showModal={showModalConfig}
                setShowModal={setShowModalConfig}
                SetPermitidos={handleSetCampos}
                permitidos={camposNaoPermitidos}
            />

            <TTable>
                <Head
                    keys={keys}
                    head={head}
                    setOrderBy={setOrderBy}
                    handleTipoOrder={handleTipoOrder}
                    setShowModalConfig={setShowModalConfig}
                    handleInputChange={handleInputChange}
                />
                {
                    data?.length > 0 ? (
                        data.map((record: any) =>
                            <Row key={record.id} record={record} edit={edit} remove={remove} camposVisualizar={camposNaoPermitidos} />
                        )
                    ) : (
                            loading ? (
                                <div style={{ position: 'fixed',  display: 'flex', width: '90%', justifyContent: 'center' }}>
                                    <Content>
                                        <h4>Carregando...</h4>
                                    </Content>
                                </div>
                            ) : (
                                    <div style={{ position: 'fixed',  display: 'flex', width: '90%', justifyContent: 'center' }}>
                                        <Content>
                                            <h4>Sem registros...</h4>
                                        </Content>
                                    </div>
                                )


                        )
                }
            </TTable>
        </>
    )

}

export default Table;
