import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    body  , input , button   {
        background: ${props => props.theme.colors.background};
        /* ${props => props.theme.colors.background}; */
        color: ${props => props.theme.colors.text};
        -webkit-font-smoothing: antialiased;
       
        font: 12px roboto,   sans-serif;

        @media (max-width: 1692px) {
            font: 13px roboto, sans-serif;
        }

        @media (max-width: 1327px) {
            font: 10px roboto, sans-serif;
        }
        
    }

    ul, li , table, tr, td, th , thead {
        color: ${props => props.theme.colors.text};
    }

    h1 , h2, h3, h4, h5, h6, strong , li , p , a {
        font-weight: 500;
    }

    html {
        font-size: 50%;
    }

 
`;