import styled, { css } from 'styled-components';

export const TTable = styled.table`
    border-collapse: collapse;
`

export const Ativo = styled.div`
    width: 10px;
    height: 10px;
    background-color: green;
    border-radius: 5px;
`

export const Inativo = styled.div`
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 5px;
`

export const Thead = styled.thead`

    tr {
        /* box-shadow: 0 0 20px rgba(0,0,0,0.1); */
        -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.10);
        -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.10);
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.10);
        th {
            background-color: ${props => props.theme.colors.primary};
            div {
            display: flex;
            align-items: center;
            background-color: ${props => props.theme.colors.primary};


            svg {
                margin-left: 2px;
                height: 100%;
                cursor: pointer;
            }

            &:hover {
            }
            
            text-align: left;
            /* padding: 14px; */

            @media (max-width: 1592px) {
                padding: 13px 2px 14px 10px;
            }

            @media (max-width: 900px) {
                padding: 13px 2px 14px 10px;
            }

            background-color: ${props => props.theme.colors.primary};
            color: ${props => props.theme.colors.text};

            padding: 8px;
            height: 48px;


        
            input {
                width: 100%;
                border: 0;
                padding-left: 2px;
                height: 25px;
                padding: 8px;
                font-weight: bold;
                color: ${props => props.theme.colors.text};
                background-color: transparent;

                
                &::placeholder {
                    color: ${props => props.theme.colors.text};
                    outline: none !important;
                }

                .primeiro {
                    @media(max-width: 1322px) {
                        width: '40px';
                    }
                }

                .segundo {
                    @media(max-width: 1322px) {
                        width: '40px'
                    }
                }

                .terceiro {
                    background-color: red;
                    width: 105px;
                }

            }
        }
                &:last-child {
                    background-color: ${props => props.theme.colors.primary};
                    
                }
        }
    }
`

export const Tbody = styled.tbody`
    tr {

        &:hover {
            background-color: ${props => props.theme.colors.select};
            cursor: pointer;
        }

        td {
           padding: 12px 8px 8px 12px;
            &:hover {
                cursor: pointer;
            }

            .ocultar {
                width: 12px;
            }

            &:last-child {
                display: flex;
                justify-content: flex-end;
            }

        }

        /* &:first-child {
            td {   
                padding: 0;
                input {
                    width: 100%;
                    border: 0;
                    padding-left: 2px;
                    height: 25px;
                }
            }
        } */
    }
`


export const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;

    h5 {
        color: ${props => props.theme.colors.select};
    }

   
`

export const Action = styled.div`
    display: flex;
    width: 80px;
    max-width: 80px;
    justify-content: space-around;
    border: 0;

    button {
        cursor: pointer;
        border: 0;
        background-color: transparent;

        &:hover {
            color: ${props => props.theme.colors.text};
        }
    }
`