import styled from 'styled-components'
import DataPicker from 'react-datepicker';


export const Container = styled.div`
   
   label {
          background: #FFF;
          display: flex;  
          position: absolute;
          margin-top: 7px;
          color: #75757D;
      }

`

export const Data = styled(DataPicker)`
    margin-top: 25px;
    border-radius: 4px;
    padding: 12px;
    width: 100%;
    height: 40px;
    border: 1px solid #EAEAEA;
    color: #75757D;
    background-color: shade(0.1, ${props => props.theme.colors.primary} );
    display: flex;
    align-items: center;

    margin-right: 12px;
`