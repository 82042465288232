
import React, { useContext, useState } from 'react';
import { useAuth } from '../../contexts/auth';
import { useHistory, Link } from 'react-router-dom';
import { FcButtingIn } from 'react-icons/fc'
import { BiColorFill } from 'react-icons/bi'

import { Container, Ul, Navigation, Header2 , Profile } from './styles';

import Switch from 'react-switch';
import { ThemeContext } from 'styled-components';
import { shade } from 'polished';
import ColorPicker from '../ColorPicker'

interface Props {
    toggleTheme(): void;
    AtualizaTheme(color: string): void;
}

const Header: React.FC<Props> = ({ toggleTheme, AtualizaTheme }) => {
    const { user, signOut } = useAuth();
    const { colors, title } = useContext(ThemeContext);
    const [showModal, setShowModal] = useState<boolean>(false);
    const history = useHistory();


    function handleSignOut() {
        history.push('/');
        signOut();
    }

    return (

        <Container>
            <Header2>
                {/* <div style={{  width: '200px' , height: '56px' , border: '1px solid #ccc' , marginTop: '2px', marginLeft: '2px' , display: 'flex', justifyContent: 'center' , alignItems: 'center'}}>
                    <h5>Logo</h5>
                </div> */}
                <div />
                <Ul>
                    <BiColorFill size={30} style={{ cursor: 'pointer', marginRight: '23px' }}
                        onClick={() => setShowModal(!showModal)}
                    />
                    <ColorPicker showModal={showModal} setShowModal={setShowModal} toggleTheme={toggleTheme} AtualizaTheme={AtualizaTheme} />

                    <Switch
                        onChange={toggleTheme}
                        checked={title == 'dark'}
                        checkedIcon={true}
                        uncheckedIcon={true}
                        height={10}
                        width={40}
                        handleDiameter={20}
                        offColor={shade(0.15, colors.secundary)}
                        onColor={colors.secundary}
                        offHandleColor="#ccc"
                    />


                    <Profile>
                        <div>
                            <strong>{user?.usuario === undefined ? 'Usuário' : user?.usuario.nome}</strong>
                            <Link to="/profile">Meu perfil</Link>
                        </div>
                        <img
                            src={
                                (user?.usuario?.foto!) ||
                                `https://vignette.wikia.nocookie.net/voidz/images/a/ac/ScottPhillips.png/revision/latest?cb=20180722000759`
                            }
                            alt="..."
                        />
                        {    console.log(user?.usuario?.foto)}
                    </Profile>
                    <a style={{ marginLeft: '8px', cursor: 'pointer'}} onClick={handleSignOut}>Sair</a>
                </Ul>
            </Header2>
        </Container>
    );
}

export default Header;