import * as Yup from 'yup';

const schema = Yup.object().shape({
    nome: Yup.string()
        .min(3)
        .max(100)
        .required('Nome é Obrigatório'),
    nome_completo: Yup.string()
        .min(3)
        .max(100)
        .required('Nome completo é Obrigatório'),
    re: Yup.string()
        .min(3, 'mínimo 3 caracteres')
        .max(10, 'max 10 caracteres'),
    logradouro: Yup.string()
        .min(5, 'mínimo 5 caracteres')
        .max(100, 'max 100 caracteres'),
    complemento: Yup.string()
        .min(5, 'mínimo 5 caracteres')
        .max(100, 'max 100 caracteres'),
    bairro: Yup.string()
        .min(3, 'mínimo 3 caracteres')
        .max(50, 'max 50 caracteres'),
    cidade: Yup.string()
        .min(3, 'mínimo 3 caracteres')
        .max(50, 'max 50 caracteres'),
    uf: Yup.string()
        .required('UF é Obrigatório'),
    tag: Yup.number()
        .min(3, 'mínimo 3 caracteres')
        .max(10, 'max 10 caracteres'),
});

export default schema;