import React, { useRef, useEffect , useState } from 'react';
import Input from '../../components/Input';
import Button from '../../components/Button';
import AvatarInput from '../../components/AvatarInput';
import { FormHandles } from '@unform/core';

import { Container, Formulario } from './styles';

import { Usuario, UsuarioReturn } from '../../model/usuario';
import { Perfil, PerfilReturn } from '../../model/perfil';
import api from '../../services/api';
import { toast } from 'react-toastify';

import schema from '../../middlewares/usuario';
import getValidationsErrors from '../../utils/getValidationsErrors';

import { useAuth } from '../../contexts/auth';

export interface User {
  usuario: Usuario;
  token: string;
}

const Profile: React.FC = () => {
  let formRef = useRef<FormHandles>(null);
  const { user, setUser } = useAuth();
  const [data, setData] = useState<Usuario>(user?.usuario!);

  interface LooseObject {
    [key: string]: any
}

  async function handleSubmit(data: Usuario) {
    try {
      formRef.current?.setErrors({});

      let dataForm: LooseObject = {};

      dataForm['nome'] =  data.nome;
      dataForm['email'] = data.email;

      
      if(data.senha !== "" ) {
        dataForm['senha'] =  data.senha!;
      }
      
      if(data.senha_antiga !== "" ) {
        dataForm['senha_antiga'] =  data.senha_antiga!;
      }

      if(data.confirma_senha !== "" ) {
        dataForm['confirma_senha'] =  data.confirma_senha!;
      }
     
      const response = await api.put<User>(`/usuarios/${user?.usuario.id}`,  dataForm )

      if (response.status == 200 || response.status == 204) {
        toast.success('Usuário Alterado com sucesso!');
      }
      
      //setUser({...user, usuario: response.data.usuario });

    }
    catch (err) {

      if (err.toString().includes('ValidationError')) {
        const errors = getValidationsErrors(err);
        formRef.current?.setErrors(errors);
      } else {
        toast.error('Falha ao gravar, verifique os valores informados');
      }
    }
  };



  return (
    <Container>
      <Formulario ref={formRef} onSubmit={handleSubmit} initialData={data}  >
        <AvatarInput />

        <Input name="nome"  placeholder="Nome" />
        <Input name="email" type="email" placeholder="E-mail" />
        <hr />
        <Input
          type="password"
          name="senha_antiga"
          placeholder="Sua senha Atual"
        />
       
        <Input 
          type="password" 
          name="senha" placeholder="Nova senha" />
        
        <Input
          type="password"
          name="confirma_senha"
          placeholder="Confirmação de senha"
        />

        <Button type="submit">Atualizar perfil</Button>
      </Formulario>
    </Container>
  );
}

export default Profile;