import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Transportadoras from '../pages/Transportadoras';
import Contratante from '../pages/Contratante';
import Motorista from '../pages/Motoristas';
import Marcas from '../pages/Marcas';
import Modelos from '../pages/Modelo';
import TipoVeiculo from '../pages/TipoVeiculo';
import Veiculos from '../pages/Veiculos';
import Rastreador from '../pages/Rastreador';
import TipoColaboradores from '../pages/TiposColaborador';
import Contato from '../pages/Contato';
import Identificador from '../pages/Identificador';
import Perfil from '../pages/Perfil';
import Profile from '../pages/Profile';
import Usuario from '../pages/Usuario';
import Turno from '../pages/Turno';
import SideMenu from '../components/SideMenu';

const AppRoutes: React.FC = ({ children }) => {

  return (
    <Router>
      <SideMenu>
          {children}
        <div style={{ padding: '15px'}}>
          <Switch>
            <Route path="/transportadoras" exact component={Transportadoras} isprivate />
            <Route path="/contratantes" component={Contratante} isprivate />
            <Route path="/motoristas" component={Motorista} isprivate />
            <Route path="/marcas" component={Marcas} isprivate />
            <Route path="/modelos" component={Modelos} isprivate />
            <Route path="/tiposveiculos" component={TipoVeiculo} isprivate />
            <Route path="/veiculos" component={Veiculos} isprivate />
            <Route path="/rastreadores" component={Rastreador} isprivate />
            <Route path="/tiposcolaboradores" component={TipoColaboradores} isprivate />
            <Route path="/contatos" component={Contato} isprivate />
            <Route path="/identificadores" component={Identificador} isprivate />
            <Route path="/perfil" component={Perfil} isprivate />
            <Route path="/profile" component={Profile} isprivate />
            <Route path="/usuarios" component={Usuario} isprivate />
            <Route path="/turnos" component={Turno} isprivate />
          </Switch>
        </div>
      </SideMenu> 
    </Router>
  );
}

export default AppRoutes;



