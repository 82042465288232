import styled, { css } from 'styled-components';
import Select from 'react-select';


interface ContainerProps {
    isFocused: boolean;
    isFilled: boolean;
}

export const Select2 = styled(Select)`
 input {
     background-color: red;
 }
`

export const Container = styled.div<ContainerProps>`

    display: flex;
    flex-direction: column;
    width: 100%;
    height: 50px;
    margin-top: 10px;
    color: ${props => props.theme.colors.text};

    label {
        margin-left: 1px;
        margin-bottom: 2px;
        display: flex;  
    }

    input {
        border: 1px solid #78b6ff;
    }
   
    
    ${(props) => props.isFocused && css`
        input {
          border: 1px solid #78b6ff;
        }   
    `} 

    ${(props) => props.isFilled && css`
        
        label {
            color: #78b6ff;
        }
    `}
    
`


