import React from 'react';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { format } from 'date-fns';
import Button from '../Button';
import { AiOutlineFilePdf } from 'react-icons/ai'
import { RiFileExcel2Line } from 'react-icons/ri'

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';


interface Props {
    data: any[];
    title: string;
    head: {};
}

interface Columns {
    header: string;
    dataKey: string;
}

const Report: React.FC<Props> = ({ data, title, head }) => {


    function ExportPDF() {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape
        //   const dataAtual = "Data Exportação" + format(Date.now(),'DD/MM/YYYY' );

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
        const columns = Object.keys(head).map(value => { return { header: value, dataKey: value } }
        )

        doc.setFontSize(10);
        doc.text(title.toUpperCase(), marginLeft, 30);
        // doc.text(dataAtual, marginLeft, 30);
        autoTable(doc, { body: data, columns, styles: { fontSize: 7, cellPadding: 2 } })
        doc.output('dataurlnewwindow')
    }




    const exportToCSV = (csvData: any, fileName: string) => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';

        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });

        FileSaver.saveAs(data, fileName + fileExtension);
    }

    return (
        <>
            <div style={{ color: '#d53250', cursor: 'pointer', width: '35px' }} onClick={() => ExportPDF()}>
                <AiOutlineFilePdf size={27} />
            </div>
            <div style={{ color: '#35802e', cursor: 'pointer', width: '35px' }} onClick={() => exportToCSV(data, title) }>
                <RiFileExcel2Line size={27} />
            </div>
        </>
    );
}

export default Report;
