import React , { ChangeEvent , Dispatch } from 'react';
import InputMask from 'react-input-mask';

import { Container } from './styles';

interface Props {
    name?: string;
    placeholder?: string;
    value?: string;
    setValue: Dispatch<string>;
}

const MaskInputTelefone:React.FC<Props> = ({ name, placeholder, value, setValue }) => {

    const ajusta = (v: any) => {
        const digitos = !v ? '' : v.replace(/[^\d]/g, '');
        if (!digitos || digitos.length < 10) return v;
        const corte = digitos.length === 10 ? 6 : 7;
        const max = digitos.length > 11 ? 11 : digitos.length;
        return `(${digitos.substring(0, 2)}) ${digitos.substring(2, corte)}-${digitos.substring(corte, max)}`;
    }

    const maskBuilder = (v: any)  => {
        if (!v || v.length == 0) return '';
        const a = ajusta(v);
        return (a.length >= 6 && a[5] === '9') ? '(99) 99999-9999' : '(99) 9999-9999';
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    }

    return (
        <Container>
              <label>{placeholder}</label>
            <InputMask
                name={name}
                value={value}
                mask={maskBuilder(value)}
                maskPlaceholder={null}
                onChange={handleChange}
            />
        </Container>
        
    )
}

export default MaskInputTelefone;