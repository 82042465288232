import React, { useState , Dispatch, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import Usuario from '../Usuario';
import Contatos from '../Contato';
import Contratos from '../Contrato';

import { Container, Titulo } from './styles';
import Mapa from '../../components/Mapa';
import api from '../../services/api';

interface Props {
    transportadora_id?: number;
    contratante_id?: number;
    visible: boolean;
    localizacao?: Localizacao;
    setLocalizacao?: Dispatch<Localizacao>;
    onSelect: (index: number, lastIndex: number) => void;
    indexPage: number;
}

interface Localizacao {
    lat: number;
    lng: number;
}

const SubMenu: React.FC<Props> = ({ children, transportadora_id, contratante_id, visible, onSelect, indexPage }) => {
    const [ localizacao, setLocalizacao ] = useState<Localizacao>({ lat: 0 , lng: 0});

    useEffect(() => {

        async function AlterarLocalizacao() {

            try {
    
                // const response = await api.put(`/contratantes/${Number(contratante_id)}`, {
                //     latitude: localizacao.lat, longitude: 123
                // })
    
             
            }
            catch (err) {
            }
        }

        AlterarLocalizacao();

    }, [localizacao])

   

    if (visible) {
        return (
            <Container>
                <Tabs onSelect={(index, last) => onSelect(index, last)} selectedIndex={indexPage}>
                    <TabList>
                        <Tab>Detalhes</Tab>
                        <Tab>Contatos</Tab>
                        <Tab>Usuários</Tab>
                        <Tab>Contratos</Tab>
                        {transportadora_id ?  <Tab>Localizacao</Tab> : <Tab>Cercas</Tab> }
                    </TabList>

                    <TabPanel>
                        {children}
                    </TabPanel>
                    <TabPanel>
                        <Contatos ocultarTitulo={true} transportadora_id={transportadora_id} contratante_id={contratante_id} />
                    </TabPanel>
                    <TabPanel>
                        <Usuario ocultarTitulo={true} transportadora_id={transportadora_id} contratante_id={contratante_id} />
                    </TabPanel>
                    <TabPanel>
                        <Contratos ocultarTitulo={true} transportadora_id={transportadora_id} contratante_id={contratante_id} />
                    </TabPanel>
                    {transportadora_id ? <TabPanel>
                            <Mapa localizacao={localizacao!} setLocalizacao={setLocalizacao!}/>
                    </TabPanel> : <TabPanel>
                        <h1>Cercas</h1>
                        </TabPanel>}

                </Tabs>
            </Container>
        )
    } else {
        return (
            <>
                {children}
            </>
        )
    }

}

export default SubMenu;