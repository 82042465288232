import styled from 'styled-components';
import { Form } from '@unform/web';

export const Container = styled.div`
    width: 100%;
    height: 80vh;
    margin-top: 22px;
    color: ${props => props.theme.colors.text};
    display: flex;
    flex-direction: column;
    
`;

export const Titulo = styled.div`
    width: 100%;
    height: 40px;
   

    h1, h2, h3 {
        color: ${props => props.theme.colors.text};
        font-weight: 700;
        margin-bottom: 12px;
    }
`
