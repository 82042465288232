import React, { useState, useRef, useEffect, useCallback, Dispatch, Children } from 'react';
import { useSpring, animated } from 'react-spring';
import { Background, Content, Head, Body, Footer, CloseModalButton, ButtonCancelar } from './styles';
import Button from '../Button';


interface PropsModal {
    titulo?: string;
    showModal: boolean;
    setShowDialog: Dispatch<boolean>;
    execute(): void;
    onNao?(): void;
}

const Dialog: React.FC<PropsModal> = ({ titulo, showModal, setShowDialog, children, execute, onNao }) => {
    const modalRef = useRef();

    const closeModal = (e: MouseEvent) => {
        if (modalRef.current === e.target) {
            setShowDialog(false);
        }
    };

    const keyPress = useCallback(
        e => {
            if (e.key === 'Escape' && showModal) {
                setShowDialog(false);
            }
        },
        [setShowDialog, showModal]
    );


    useEffect(
        () => {
            document.addEventListener('keydown', keyPress);
            return () => document.removeEventListener('keydown', keyPress);
        },
        [keyPress]
    );

    const handleNao = () => {
        if (onNao) {
            onNao();
        }
        setShowDialog(false);
    }

    const animation = useSpring({
        config: {
            duration: 250
        },
        opacity: showModal ? 1 : 0,
        transform: showModal ? `translateY(0%)` : `translateY(-100%)`
    });


  return (
    <>
    { showModal ? (
        <Background >
            <animated.div style={animation}>
                <Content>

                    <Head>
                        <a>{titulo}</a>
                        <CloseModalButton
                            onClick={() => setShowDialog(false)}
                        />
                    </Head>

                    <Body>
                        {children}

                        
                    </Body>

                    <Footer>
                        <div>
                            <ButtonCancelar onClick={handleNao}>Não</ButtonCancelar>
                        </div>
                        <div>
                            <Button onClick={() => execute()}>Sim</Button>
                        </div>
                    </Footer>
                </Content>
            </animated.div>
        </Background >
    ) : null
    }
</>
  );
}

export default Dialog;