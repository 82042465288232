import * as Yup from 'yup';


const schema = Yup.object().shape({
    tag: Yup.string()
        .min(3)
        .max(100)
        .required('Tag é Obrigatório'),
    tipocracha: Yup.string()
        .min(3)
        .max(100)
        .required('Tipo do Cracha é Obrigatório'),
});

export default schema;