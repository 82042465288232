const Sigla = (fullName : string): string => {
    
    if(fullName !== undefined) {

        var sigla = fullName.substr(0,2);
        return sigla.toUpperCase();
    } else {
        return '';              
    }
}

export default Sigla;