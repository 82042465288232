import React, { useState, useEffect, useRef, useCallback , ChangeEvent } from 'react';
import { Container, Titulo, Formulario, Group, Grid, Row, Col } from './styles';
import { FormHandles } from '@unform/core';
import { toast } from 'react-toastify';

import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import Novo from '../../components/Novo';
import Input from '../../components/Input';
import Button from '../../components/Button';
import api from '../../services/api';
import Modal from '../../components/Modal';
import Dialog from '../../components/Dialog';
import Select, { SelectType , SelectDefault } from '../../components/Select';

import getValidationsErrors from '../../utils/getValidationsErrors';
import useDebounce from '../../utils/useDebounce';

import { Identificador, IdentificadorReturn } from '../../model/identificador';

import schema from '../../middlewares/identificador';


const Identificadores: React.FC = () => {
    let formRef = useRef<FormHandles>(null);

    const [editIdentificador, setEditIdentificador] = useState<any>({});
    const [data, setData] = useState<Identificador[]>([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage, setDataPerPage] = useState(13);
    const [selectedTipoCracha, setSelectedTipoCracha] = useState<SelectType>(SelectDefault);

    const [selectedOption, setSelectedOption] = useState<string>('Ativo');
    const [showModal, setShowModal] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    //Paginação 
    const indexOfLastData = currentPage * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;
    const currentDatas = data;
    const [count, setCount] = useState<number>(0);

    const [orderBy, setOrderBy] = useState<string>('id');
    const [tipoOrder, setTipoOrder] = useState<string>('Asc');

    const array: SelectType[] = [{ value: 'RFID', label: 'RFID' },
    { value: 'OUTROS', label: 'OUTROS' }];

    
    const paginate = (pageNumber: number) => setCurrentPage(pageNumber)
    const openModal = () => setShowModal(true);
    
    
    const [filtro, setFiltro ] = useState<Identificador>({} as Identificador);

    const debounce = useDebounce();

    function handleFiltroChange(event: ChangeEvent<HTMLInputElement>){
        const {name, value} = event.target;
        debounce(() => setFiltro({ ...filtro, [name]: `%${value.toUpperCase()}%` }), 1000)
    }

    const head = {
        id: "ID",
        tag: "Tag",
        tipocracha: "Tipo do Cracha",
        ativo: "Ativo",
    };


    useEffect(() => {
        Pesquisar();
    }, [dataPerPage, currentPage, orderBy, tipoOrder , selectedOption, filtro ])

    const Pesquisar = async () => {
        setLoading(true);
        const res = await api.get<IdentificadorReturn>('/identificadores', {
            params: {
                page: currentPage,
                    size: dataPerPage,
                    orderBy: orderBy,
                    tipoOrder: tipoOrder,
                    transportadora_id: 1,
                    ativo: selectedOption == 'Ativo' ? 1 : 0,
                    filtro: {  ...filtro  }
            } 
        });

        if (res.data && res.data.itens) {
            setData(res.data.itens);
            setCount(Number(res.data.count));
        } else {
            setData([]);
        }
        setLoading(false);
        setShowModal(false);
    }

    async function handleEdit(data: any) {

        setTimeout(() => {
            formRef.current?.setData(data)
        }, 200);
        const selectTipoCra: SelectType = array.filter(a => a.value === data.tipocracha)[0];
        setSelectedTipoCracha(selectTipoCra);
        setEditIdentificador(data);
        setShowModal(true);
    };

    function handleDialog(data: any) {
        setShowDialog(true);
        setEditIdentificador(data);
    }

    async function handleInative() {
        try {
            const response = await api.put(`/identificadores/${Number(editIdentificador.id)}`, {
                ...data, ativo: false
            })

            if (response.status == 200 || response.status == 204) {
                toast.success('Identificador Inativado com sucesso!');
                setEditIdentificador({});
                setShowDialog(false);
            }
        } catch (err) {
            toast.error('Falha ao inativar o registro!');
            setEditIdentificador({});
            setShowDialog(false);
        }
    }

    async function handleSubmit(data: Identificador) {
        try {
            formRef.current?.setErrors({});

            await schema.validate(data, {
                abortEarly: false
            });

            if (Number(editIdentificador.id) > 0) {

                const response = await api.put(`/identificadores/${Number(editIdentificador.id)}`, {
                    ...data,
                })

                if (response.status == 200 || response.status == 204) {
                    toast.success('Identificador Alterado com sucesso!');
                    setEditIdentificador({});
                    Pesquisar();
                }

            } else {
                const response = await api.post("/identificadores", {
                    ...data, ativo: true , transportadora_id: 1
                })

                if (response.status == 200 || response.status == 204) {
                    toast.success('Identificador criado com sucesso!');
                    setEditIdentificador({});
                    Pesquisar();
                }
            }
        }
        catch (err) {

            if (err.toString().includes('ValidationError')) {
                const errors = getValidationsErrors(err);
                formRef.current?.setErrors(errors);
            } else {
                toast.error('Falha ao gravar, verifique os valores informados');
            }
        }
    };

    function handleShowModal(value : boolean) {
        setShowModal(value);
        setSelectedTipoCracha(SelectDefault);
        setEditIdentificador({});
    }


    function handleTipoOrder() { setTipoOrder(tipoOrder == 'Asc' ? 'Desc' : 'Asc') };

    const handleSelectedTipoCracha = useCallback(async (value: any, actionMeta: any) => {
        if (value) {
            setSelectedTipoCracha(value);
        } else {
            setSelectedTipoCracha(SelectDefault);
        }
    }, [])


    return (
        <Container>

            <Titulo>
                <h2>Identificador</h2>
            </Titulo>

            <Novo
                openModal={openModal}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                data={data}
                head={head}
                title="Identificador"
            />

            <Dialog titulo="Confirma a inativação do registro?"
                showModal={showDialog}
                setShowDialog={setShowDialog}
                execute={handleInative}
            >
                <h2>ID: {editIdentificador.id} </h2>
                <h2>Tag: {editIdentificador.tag}</h2>

            </Dialog>

            <Modal titulo="Cadastro de Identificador" showModal={showModal} setShowModal={setShowModal} handleShowModal={handleShowModal} >
                <Formulario ref={formRef} onSubmit={handleSubmit}  >
                    <Grid>
                        <Row>
                            <Input name="tag" id="tag" placeholder="Tag" />
                        </Row>
                        <Row>
                            <Col size={1} >
                                <Select
                                    options={array}
                                    placeHolder="Selecione um Tipo"
                                    handleSelected={handleSelectedTipoCracha}
                                    selectedValue={selectedTipoCracha}
                                    name="tipocracha"
                                />
                            </Col>
                        </Row>
                    </Grid>
                    <Button type="submit">Salvar</Button>
                </Formulario>
            </Modal>

            <Table
                data={currentDatas}
                head={head}
                numeroTela="11"
                loading={loading}
                edit={handleEdit}
                remove={handleDialog}
                setOrderBy={setOrderBy}
                handleTipoOrder={handleTipoOrder}
                handleInputChange={handleFiltroChange}
            />

            <Pagination
                postsPerPage={dataPerPage}
                totalPosts={count}
                paginate={paginate}
            />
        </Container>
    )
}

export default Identificadores;