import React, { useState, useEffect, useRef, useCallback, ChangeEvent } from 'react';
import { Container, Titulo, Formulario, Group, Grid, Row, Col } from './styles';
import { FormHandles } from '@unform/core';
import { toast } from 'react-toastify';

import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import Novo from '../../components/Novo';
import Input from '../../components/Input';
import Button from '../../components/Button';
import api from '../../services/api';
import Modal from '../../components/Modal';
import Dialog from '../../components/Dialog';
import Select, { SelectType, SelectDefault } from '../../components/Select';

import InputMaskTelefone from "../../components/MaskInputTel";

import getValidationsErrors from '../../utils/getValidationsErrors';
import useDebounce from '../../utils/useDebounce';

import { Contato, ContatoReturn } from '../../model/contato';

import { Transportadora, TransportadoraReturn } from '../../model/Transportadora';
import { Contratante, ContratanteReturn } from '../../model/contratante';

import schema from '../../middlewares/contato';
import { handleInputChange } from 'react-select/src/utils';


interface Props {
    ocultarTitulo?: boolean;
    transportadora_id?: number;
    contratante_id?: number;
}

const Contatos: React.FC<Props> = ({ ocultarTitulo, transportadora_id, contratante_id }) => {
    let formRef = useRef<FormHandles>(null);

    const [edit, setEdit] = useState<any>({});
    const [data, setData] = useState<Contato[]>([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage, setDataPerPage] = useState(13);

    const [transportadoras, setTransportadoras] = useState<Transportadora[]>([]);
    const [selectedTransportadora, setSelectedTransportadora] = useState<SelectType>(SelectDefault);

    const [contratante, setContratante] = useState<Transportadora[]>([]);
    const [selectedContratante, setSelectedContratante] = useState<SelectType>(SelectDefault);

    const [selectedOption, setSelectedOption] = useState<string>('Ativo');
    const [showModal, setShowModal] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    //Paginação 
    const indexOfLastData = currentPage * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;
    const currentDatas = data;
    const [count, setCount] = useState<number>(0);

    const [orderBy, setOrderBy] = useState<string>('id');
    const [tipoOrder, setTipoOrder] = useState<string>('Asc');

    const [valueTelefone1, setValueTelefone1] = useState('');
    const [valueTelefone2, setValueTelefone2] = useState('');


    const paginate = (pageNumber: number) => setCurrentPage(pageNumber)
    const openModal = () => setShowModal(true);

    const [filtro, setFiltro] = useState<Contato>({} as Contato);

    const debounce = useDebounce();

    function handleFiltroChange(event: ChangeEvent<HTMLInputElement>) {
        const { name, value } = event.target;
        debounce(() => setFiltro({ ...filtro, [name]: `%${value.toUpperCase()}%` }), 1000)
    }

    const head = {
        id: "Id",
        area: "Área",
        nome: "Nome",
        telefone1: "Telefone1",
        telefone2: "Telefone2",
        email: "E-mail",
        ativo: "Ativo"
    };

    useEffect(() => {
        Pesquisar();
    }, [dataPerPage, currentPage, orderBy, tipoOrder, selectedOption, filtro])

    const Pesquisar = async () => {
        setLoading(true);
        const res = await api.get<ContatoReturn>('/contatos', {
            params: {
                page: currentPage,
                size: dataPerPage,
                orderBy: orderBy,
                tipoOrder: tipoOrder,
                ativo: selectedOption == 'Ativo' ? 1 : 0,
                transportadora_id,
                contratante_id,
                filtro: {
                    ...filtro
                }
            }
        });

        if (res.data && res.data.itens) {
            const data = res.data.itens.map(i => ({
                ...i,
                descricaoTransportadora: i.transportadora?.razaosocial,
                descricaoContratante: i.contratante?.razaosocial
            }))

            setData(data);
            setCount(Number(res.data.count));
        } else {
            setData([]);
        }
        setLoading(false);
        setShowModal(false);
    }

    useEffect(() => {
        const PesquisarTransportadoras = async () => {
            const res = await api.get<TransportadoraReturn>('/transportadoras', {
                params: {
                    filtro: {}
                }
            });

            if (res.data && res.data.itens) {
                setTransportadoras(res.data.itens);
            } else {
                setTransportadoras([]);
            }
        }
        PesquisarTransportadoras();
    }, [])

    useEffect(() => {
        const PesquisaContratantes = async () => {
            const res = await api.get<ContratanteReturn>('/contratantes', {
                params: {
                    filtro: {}
                }
            });

            if (res.data && res.data.itens) {
                setContratante(res.data.itens);
            } else {
                setContratante([]);
            }
        }
        PesquisaContratantes();
    }, [])

    useEffect(() => {
        setValueTelefone1('');
        setValueTelefone2('');
    }, [])

    async function handleEdit(data: any) {

        setTimeout(() => {
            formRef.current?.setData(data)
        }, 200);

        setEdit(data);
        // setSelectedTransportadora({
        //     value: data.transportadora.id,
        //     label: data.transportadora.razaosocial
        // });
        setShowModal(true);
    };

    function handleDialog(data: any) {
        setShowDialog(true);
        setEdit(data);
    }

    async function handleInative() {
        try {
            const response = await api.put(`/contatos/${Number(edit.id)}`, {
                ...data, ativo: false
            })

            if (response.status == 200 || response.status == 204) {
                toast.success('Contato Inativado com sucesso!');
                setEdit({});
                setShowDialog(false);
            }
        } catch (err) {
            toast.error('Falha ao inativar o registro!');
            setEdit({});
            setShowDialog(false);
        }
    }

    async function handleSubmit(data: Contato) {
        try {
            formRef.current?.setErrors({});

            await schema.validate(data, {
                abortEarly: false
            });

            if (Number(edit.id) > 0) {

                const response = await api.put(`/contatos/${Number(edit.id)}`, {
                    ...data, transportadora_id, contratante_id, telefone1: valueTelefone1, telefone2: valueTelefone2
                })

                if (response.status == 200 || response.status == 204) {
                    toast.success('Contato Alterado com sucesso!');
                    setEdit({});
                    setValueTelefone1('');
                    setValueTelefone2('');
                    Pesquisar();
                }

            } else {
                const response = await api.post("/contatos", {
                    ...data, ativo: true, transportadora_id, contratante_id, telefone1: valueTelefone1, telefone2: valueTelefone2
                })

                if (response.status == 200 || response.status == 204) {
                    toast.success('Contato criado com sucesso!');
                    setEdit({});
                    setValueTelefone1('');
                    setValueTelefone2('');
                    Pesquisar();
                }
            }
        }
        catch (err) {

            if (err.toString().includes('ValidationError')) {
                const errors = getValidationsErrors(err);
                formRef.current?.setErrors(errors);
            } else {
                toast.error('Falha ao gravar, verifique os valores informados');
            }
        }
    };

    const handleSelectedTranpostadora = useCallback(async (value: any, actionMeta: any) => {
        if (value) {
            setSelectedTransportadora(value);
        } else {
            setSelectedTransportadora(SelectDefault);
        }
    }, [])

    const handleSelectedContratante = useCallback(async (value: any, actionMeta: any) => {
        if (value) {
            setSelectedContratante(value);
        } else {
            setSelectedContratante(SelectDefault);
        }
    }, [])

    function handleTipoOrder() { setTipoOrder(tipoOrder == 'Asc' ? 'Desc' : 'Asc') };

    const optionsTransportadoras: any[] = [];

    transportadoras.forEach(p => {
        optionsTransportadoras.push({ value: p.id, label: p.razaosocial });
    });

    const optionsContratantes: any[] = [];

    contratante.forEach(p => {
        optionsContratantes.push({ value: p.id, label: p.razaosocial });
    });

    function handleShowModal(value: boolean) {
        setShowModal(value);
        setSelectedTransportadora(SelectDefault);
        setSelectedContratante(SelectDefault);
        setEdit({});
    }

    return (
        <Container>

            { !ocultarTitulo && (
                <Titulo>
                    <h2>Contatos</h2>
                </Titulo>
            )
            }

            <Novo
                openModal={openModal}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                data={data}
                head={head}
                title="Contatos"
            />

            <Dialog titulo="Confirma a inativação do registro?"
                showModal={showDialog}
                setShowDialog={setShowDialog}
                execute={handleInative}
            >
                <h2>ID: {edit.id} </h2>
                <h2>Descrição: {edit.nome}</h2>

            </Dialog>

            <Modal titulo="Cadastro de Contato" showModal={showModal} setShowModal={setShowModal} handleShowModal={handleShowModal}>
                <Formulario ref={formRef} onSubmit={handleSubmit}  >

                    <Grid>
                        <Row>
                            <Col size={1}>
                                <Input name="area" id="area" placeholder="Área" />
                            </Col>
                            <Col size={2} >
                                <Input name="nome" id="nome" placeholder="Nome" />
                            </Col>
                        </Row>
                        <Row>
                            <Col size={1} >
                                <InputMaskTelefone value={valueTelefone1} setValue={setValueTelefone1} placeholder="Telefone 1" />
                            </Col>
                            <Col size={1} >
                                <InputMaskTelefone value={valueTelefone2} setValue={setValueTelefone2} placeholder="Telefone 2" />
                            </Col>
                        </Row>
                        <Row>
                            <Col size={1} >
                                <Input name="email" type="email" placeholder="E-mail" />
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col size={1}>
                                <Select
                                    options={optionsTransportadoras}
                                    placeHolder="Selecione a Transportadora"
                                    handleSelected={handleSelectedTranpostadora}
                                    selectedValue={selectedTransportadora}
                                    name="transportadoras"
                                />
                            </Col>
                            <Col size={1}>
                                <Select
                                    options={optionsContratantes}
                                    placeHolder="Selecione o Contratante"
                                    handleSelected={handleSelectedContratante}
                                    selectedValue={selectedContratante}
                                    name="contratantes"
                                />
                            </Col>
                        </Row> */}

                    </Grid>

                    <Button type="submit">Salvar</Button>
                </Formulario>
            </Modal>

            <Table
                data={currentDatas}
                head={head}
                loading={loading}
                numeroTela="5"
                edit={handleEdit}
                remove={handleDialog}
                setOrderBy={setOrderBy}
                handleTipoOrder={handleTipoOrder}
                handleInputChange={handleFiltroChange}
            />

            <Pagination
                postsPerPage={dataPerPage}
                totalPosts={count}
                paginate={paginate}
            />
        </Container>
    )
}

export default Contatos;