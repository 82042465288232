import React, { useEffect, useState, Dispatch } from 'react';
import Button from '../Button';
import { Container, Form } from './styles';
import { FiPlus } from 'react-icons/fi'
import ReportPdf from '../../components/ReportPdf';


interface PropsButton {
  openModal(): void;
  setSelectedOption: Dispatch<string>;
  selectedOption: string;
  data: any[];
  title: string;
  head: {};
}


const Novo: React.FC<PropsButton> = ({ openModal , selectedOption , setSelectedOption , data, head, title ,...rest }) => {

  useEffect(() => {
    setSelectedOption('Ativo');
  },[])


  return (
    <Container>
      <div>

        <Form>
          <div className="radio">
            <label>
              <input type="radio" value="Ativo" checked={selectedOption === 'Ativo'} onChange={event => setSelectedOption(event.target.value)} />
             Ativo
            </label>
          </div>
          <div className="radio">
            <label>
              <input type="radio" value="Inativo" checked={selectedOption === 'Inativo'} onChange={event => setSelectedOption(event.target.value)} />
        Inativo
      </label>
          </div>
         
        </Form>
      </div>
      <ReportPdf data={data} title={title} head={head}/>
      <div style={{ marginLeft: '4px'}}>
        <Button icon={FiPlus} onClick={() => openModal()}>Novo</Button>
      </div>
    </Container>
  );
}

export default Novo;