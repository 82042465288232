import React, { useState, Dispatch, useEffect } from 'react';

import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from 'react-datepicker';
import pt from 'date-fns/locale/pt';

import { Container , Data as DataPicker} from './styles';

interface Props {
  handleDataChange(event: Date): void;
  setDataSelecionada: Dispatch<Date>;
  dataSelecionada: Date;
}

const DataInput: React.FC<Props> = ({ handleDataChange, dataSelecionada, setDataSelecionada }) => {

  useEffect(() => {
    setDataSelecionada(new Date());
  }, [])

  return (
    <Container>
      <label>Data Admissão</label>
      <DataPicker
        selected={dataSelecionada}
        onChange={handleDataChange}
        locale="pt"
        dateFormat="P"
      />
    </Container>
  )
}

export default DataInput;