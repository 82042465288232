import * as Yup from 'yup';


const schema = Yup.object().shape({
    area: Yup.string()
        .min(3)
        .max(100)
        .required('Area é Obrigatório'),
    nome: Yup.string()
        .min(3)
        .max(100)
        .required('Nome é Obrigatório'),
    telefone1: Yup.string()
        .min(11, 'mínimo 11 caracteres')
        .max(20,'max 20 caracteres'),
    telefone2: Yup.string()
        .min(11, 'mínimo 11 caracteres')
        .max(20,'max 20 caracteres'),
    email: Yup.string(),
});

export default schema;