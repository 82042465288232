
import React, {
    useEffect,
    useRef,
    useState,
    useCallback,
    InputHTMLAttributes,
    ChangeEvent
} from 'react';

import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import { Container, Error } from './styles';
import { useField } from '@unform/core';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    icon?: React.ComponentType<IconBaseProps>;
    showPlaceHolder?: boolean;
}

const Input: React.FC<InputProps> = ({
    name,
    icon: Icon,
    placeholder,
    showPlaceHolder,
    ...rest
}) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const { fieldName, defaultValue, error, registerField } = useField(String(name));

    useEffect(() => {

        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value'
        })
    }, [fieldName, registerField])

    const [isFocused, setIsFocused] = useState(!!inputRef.current?.value);
    const [isFilled, setIsFilled] = useState(!!inputRef.current?.value);

    useEffect(() => {
        setIsFocused(!!inputRef.current?.value);
        setIsFilled(!!inputRef.current?.value)
        // inputRef.current?.value === inputRef.current?.value.toUpperCase();
    }, [inputRef.current?.value])

    const handleInputBlur = useCallback(() => {
        setIsFocused(!!inputRef.current?.value);
        setIsFilled(!!inputRef.current?.value)
    }, [])

    return (
        <Container
            isFocused={isFocused}
            isFilled={isFilled}
            isErrored={!!error}
            restType={rest.type}
        >
            {Icon && <Icon size={20} />}

            {
                showPlaceHolder == true || showPlaceHolder == undefined ? (
                    <>
                        <label>{placeholder}</label>
                        <input
                            {...rest}
                            defaultValue={defaultValue}
                            onFocus={() => setIsFocused(true)}
                            onBlur={handleInputBlur}
                            ref={inputRef}
                            autoComplete="off"
                            onChange={value => {
                                value.target.value = rest.type != "email" ? value.target.value.toUpperCase() : value.target.value;
                                if (rest.onChange) {
                                    rest.onChange(value);
                                }
                            }}
                        />
                    </>
                ) : (
                        <input
                            {...rest}
                            defaultValue={defaultValue}
                            onFocus={() => setIsFocused(true)}
                            onBlur={handleInputBlur}
                            placeholder={placeholder}
                            ref={inputRef}
                            autoComplete="off"
                        />
                    )
            }


            {error &&
                <Error title={error}>
                    <FiAlertCircle color="#c53030" size={20} />
                </Error>}
        </Container>
    )
}

export default Input;