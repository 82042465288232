import styled from 'styled-components';
import signInBack from '../../assets/sign-in-background.png';
import { shade } from 'polished';

export const Container = styled.div`
    width: 100%;
    max-width: 1120px;
    height: 100vh;
    margin: 0 auto;

    display: flex;
    align-items: center;
    justify-content: center;
    

`;

export const Content = styled.div`
    background-color: ${props => props.theme.colors.background};
    display: flex;
    height: 600px;
    width: 850px;
    border-radius: 10px;
   
    border: 1px solid #EAEAEA;
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);
`;

export const Session1 = styled.div`
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);
    display: flex;
    background: linear-gradient(120deg, #1259F0, #78b6ff);
    width: 50%;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    img {
        width: 330px;
    }
`;



export const Session2 = styled.div`
    display: flex; 
    width: 50%;
    border-radius: 32px;
    align-items: center;
    justify-content : center;
    
    form {
        width: 75%;
        h1 {
            font-size: 32px;
            margin-bottom: 32px;
        }
    }
`;


