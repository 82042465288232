import React, { useState, useEffect, useRef, useCallback, ChangeEvent, useMemo } from 'react';
import { Container, Titulo, Formulario, Group, Grid, Col, Row } from './styles';
import { FormHandles } from '@unform/core';
import { toast } from 'react-toastify';

import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import Novo from '../../components/Novo';
import Input from '../../components/Input';
import Button from '../../components/Button';
import api from '../../services/api';
import Modal from '../../components/Modal';
import Dialog from '../../components/Dialog';
import SubMenu from '../SubMenu';

import Select, { SelectDefault, SelectType } from '../../components/Select';

import getValidationsErrors from '../../utils/getValidationsErrors';
import useDebounce from '../../utils/useDebounce';

import { Transportadora, TransportadoraReturn } from '../../model/Transportadora';
import IBGEUF from '../../model/ibgeUF';
import IBGECITY from '../../model/ibgeCITY';

import schema from '../../middlewares/transportadora';
import { setOriginalNode } from 'typescript';

const Transportadoras: React.FC = () => {
    let formRef = useRef<FormHandles>(null);


    const [data, setData] = useState<Transportadora[]>([]);

    const [edit, setEdit] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage, setDataPerPage] = useState(13);


    const [editando, setEditando] = useState<boolean>(false);

    const [ufs, setUfs] = useState<string[]>([]);
    const [cities, setCities] = useState<string[]>([]);

    const [selectedTipoAgrupamento, setSelectedTipoAgrupamento] = useState<SelectType>(SelectDefault);
    const [selectedUf, setSelectedUf] = useState<SelectType>(SelectDefault);
    const [selectedCity, setSelectedCity] = useState<SelectType>(SelectDefault);

    const [selectedOption, setSelectedOption] = useState<string>('Ativo');
    const [showModal, setShowModal] = useState(false);
    const [showDialog, setShowDialog] = useState(false);

    const [showDialogEditar, setShowDialogEditar] = useState(false);

    const [page, setPage] = useState<number>(0);
    const [proxPage, setProxPage] = useState<number>(0);

    //Paginação 
    const indexOfLastData = currentPage * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;
    const currentDatas = data;
    const [count, setCount] = useState<number>(0);

    const [orderBy, setOrderBy] = useState<string>('id');
    const [tipoOrder, setTipoOrder] = useState<string>('Asc');
    const [handleOnNao, setHandleOnNao] = useState(undefined);

    const paginate = (pageNumber: number) => setCurrentPage(pageNumber)
    const openModal = () => setShowModal(true);

    const tipoContrato: SelectType[] = [{ value: 'GRUPOCONTRATANTE', label: 'Grupo Contratante' },
    { value: 'MULTICONTRATANTE', label: 'Multi Contratante' }];

    const [filtro, setFiltro] = useState<Transportadora>({} as Transportadora);

    const debounce = useDebounce();

    function handleFiltroChange(event: ChangeEvent<HTMLInputElement>) {
        const { name, value } = event.target;
        debounce(() => setFiltro({ ...filtro, [name]: `%${value.toUpperCase()}%` }), 1000)
    }

    const head = {
        id: "ID",
        razaosocial: "Razão Social",
        nomefantasia: "Nome Fantasia",
        sigla: "Sigla",
        logradouro: "Logradouro",
        numero: "Número",
        complemento: "Complemento",
        bairro: "Bairro",
        cidade: "Cidade",
        uf: "UF",
        tipoagrupamento: "Tipo Agrupamento",
        ativo: "Ativo",
    };


    useEffect(() => {
        Pesquisar();
    }, [dataPerPage, currentPage, orderBy, tipoOrder, selectedOption, filtro])


    const Pesquisar = async () => {
        setLoading(true);
        const res = await api.get<TransportadoraReturn>('/transportadoras', {
            params: {
                page: currentPage,
                size: dataPerPage,
                orderBy: orderBy,
                tipoOrder: tipoOrder,
                ativo: selectedOption == 'Ativo' ? 1 : 2,
                filtro: { ...filtro }
            }
        });

        if (res.data && res.data.itens) {
            setData(res.data.itens);
            setCount(Number(res.data.count));
            //  setSelectedUf({ label: res.data.itens[0].uf , value: res.data.itens[0].uf})

        } else {
            setData([]);
        }
        setLoading(false);
        // setShowModal(false);
    }


    useEffect(() => {
        api.get<IBGEUF[]>('https://servicodados.ibge.gov.br/api/v1/localidades/estados').then(response => {
            const aa = response.data.map(uf => uf.sigla);
            setUfs(aa);
        });
    }, []);

    useEffect(() => {
        if (selectedUf.value == undefined || selectedUf.value == null) {
            return;
        }

        api.get<IBGECITY[]>(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${selectedUf.value}/municipios`)
            .then(response => {
                const cityNames = response.data.map(city => city.nome);
                setCities(cityNames);
            });
    }, [selectedUf]);

    async function handleEdit(data: any) {

        setTimeout(() => {
            formRef.current?.setData(data)
        }, 200);

        setSelectedCity({ value: data.cidade, label: data.cidade });
        setSelectedUf({ value: data.uf, label: data.uf });
        const selectTipoAgru: SelectType = tipoContrato.filter(a => a.value === data.tipoagrupamento)[0];
        setSelectedTipoAgrupamento(selectTipoAgru);
        setEdit(data);
        setEditando(false);
        setPage(0);
        setShowModal(true);
    };

    function handleDialog(data: any) {
        setShowDialog(true);
        setEdit(data);
    }

    async function handleInative() {
        try {
            const response = await api.put(`/transportadoras/${Number(edit.id)}`, {
                ...data, ativo: false
            })

            if (response.status == 200 || response.status == 204) {
                toast.success('Transportadora Inativada com sucesso!');
                setEdit({});
                setShowDialog(false);
            }
        } catch (err) {
            toast.error('Falha ao inativar o registro!');
            setEdit({});
            setShowDialog(false);
        }
    }

    async function handleSubmit(data: Transportadora) {
        try {
            formRef.current?.setErrors({});

            await schema.validate(data, {
                abortEarly: false
            });

            if (Number(edit.id) > 0) {

                const response = await api.put(`/transportadoras/${Number(edit.id)}`, {
                    ...data
                })

                if (response.status == 200 || response.status == 204) {
                    toast.success('Transportadora Alterada com sucesso!');
                    // setShowModal(false);
                    setEdit(response.data);
                    Pesquisar();
                }

            } else {
                const response = await api.post("/transportadoras", {
                    ...data, ativo: true
                })

                if (response.status == 200 || response.status == 204) {
                    toast.success('Transportadora criada com sucesso!');

                    // setShowModal(false);
                    Pesquisar();
                    setEdit(response.data);
                }
            }
        }
        catch (err) {
            if (err.toString().includes('ValidationError')) {
                const errors = getValidationsErrors(err);
                formRef.current?.setErrors(errors);
            } else {
                toast.error('Falha ao gravar, verifique os valores informados');
            }
        }
    };

    const handleSelectedTipoContrato = useCallback(async (value: any, actionMeta: any) => {
        if (value) {
            setSelectedTipoAgrupamento(value);
        } else {
            setSelectedTipoAgrupamento(SelectDefault);
        }
    }, [])

    const handleSelectedUF = useCallback(async (value: any, actionMeta: any) => {
        if (value) {
            setSelectedUf(value);
        } else {
            setSelectedUf(SelectDefault);
        }
    }, [])

    const handleSelectedCity = useCallback(async (value: any, actionMeta: any) => {
        if (value) {
            setSelectedCity(value);
        } else {
            setSelectedCity(SelectDefault);
        }
    }, [])

    function handleTipoOrder() { setTipoOrder(tipoOrder == 'Asc' ? 'Desc' : 'Asc') };

    function handleShowModal(value: boolean) {
        setShowModal(value);
        setSelectedTipoAgrupamento(SelectDefault);
        setSelectedCity(SelectDefault);
        setSelectedUf(SelectDefault);
        setEdit({});
    }

    function alterouPagina(index: number, last: number) {
        if (last === 0 && index !== 0) {
            if (editando) {
                setProxPage(index);
                //setHandleOnNao(handle);
                setShowDialogEditar(true);
            } else {
                setPage(index);
            }
        } else {
            setPage(index);
        }

    }

    return (
        <Container>

            <Titulo>
                <h2>Transportadoras </h2>
            </Titulo>


            <Novo
                openModal={openModal}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                data={data}
                head={head}
                title="Transportadoras"
            />

            <Dialog titulo="Confirma a inativação do registro?"
                showModal={showDialog}
                setShowDialog={setShowDialog}
                execute={handleInative}
            >
                <h2>Razão Social: {edit.razaosocial} </h2>
                <h2>Nome Fantasia: {edit.nomefantasia}</h2>

            </Dialog>

            <Dialog titulo=""
                showModal={showDialogEditar}
                setShowDialog={setShowDialogEditar}
                execute={() => {
                    setEditando(false)
                }}
                onNao={() => setPage(proxPage)}
            >
                <h2>
                    Deseja salvar a alteração?
                </h2>

            </Dialog>

            <Modal titulo="Cadastro de Transportadora" showModal={showModal} setShowModal={setShowModal} handleShowModal={handleShowModal} >

                <SubMenu transportadora_id={Number(edit.id)} visible={edit.id > 0} onSelect={alterouPagina} indexPage={page}>
                    <Formulario ref={formRef} onSubmit={handleSubmit} initialData={edit}
                        onInputCapture={() => setEditando(true)}
                    >
                        <Grid>
                            <Row>
                                <Col size={1}>
                                    <Input name="razaosocial" id="razaosocial" placeholder="Razão Social" className="grande" />
                                </Col>
                                <Col size={1} >
                                    <Input name="nomefantasia" placeholder="Nome Fantasia" />
                                </Col>
                            </Row>

                            <Row>
                                <Col size={1} >
                                    <Input name="sigla" placeholder="Sigla" />
                                </Col>
                                <Col size={5} >
                                    <Input name="logradouro" placeholder="Logradouro" />
                                </Col>

                            </Row>
                            <Row>
                                <Col size={1} >
                                    <Input name="numero" placeholder="Número" />
                                </Col>
                                <Col size={3} >
                                    <Input name="complemento" placeholder="Complemento" />
                                </Col>
                                <Col size={2} >
                                    <Input name="bairro" placeholder="Bairro" />
                                </Col>
                            </Row>
                            <Row>
                                <Col size={1}>
                                    <Select
                                        options={ufs.map(p => ({ value: p, label: p }))}
                                        placeHolder="Selecione a UF"
                                        handleSelected={handleSelectedUF}
                                        selectedValue={selectedUf}
                                        name="uf"
                                    />
                                </Col>
                                <Col size={1}>
                                    <Select
                                        options={cities.map(p => ({ value: p, label: p }))}
                                        placeHolder="Selecione a cidade"
                                        handleSelected={handleSelectedCity}
                                        selectedValue={selectedCity}
                                        name="cidade"
                                    />
                                </Col>
                                <Col size={1}>
                                    <Select
                                        options={tipoContrato}
                                        placeHolder="Selecione o Agrupamento"
                                        handleSelected={handleSelectedTipoContrato}
                                        selectedValue={selectedTipoAgrupamento}
                                        name="tipoagrupamento"
                                    />
                                </Col>
                            </Row>
                        </Grid>

                      
                        <Button type="submit" style={{ marginTop: '25px' }}>Salvar</Button>
                    </Formulario>
                </SubMenu>

            </Modal>

            <Table
                data={currentDatas}
                head={head}
                numeroTela="3"
                loading={loading}
                edit={handleEdit}
                remove={handleDialog}
                setOrderBy={setOrderBy}
                handleTipoOrder={handleTipoOrder}
                handleInputChange={handleFiltroChange}
            />

            <Pagination
                postsPerPage={dataPerPage}
                totalPosts={count}
                paginate={paginate}
            />
        </Container>
    )
}

export default Transportadoras;

