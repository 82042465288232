import React, { ButtonHTMLAttributes } from 'react';
import { Container } from './styles';
import { FiAlertCircle } from 'react-icons/fi';
import { IconBaseProps } from 'react-icons';

interface ButtonProps extends  ButtonHTMLAttributes<HTMLButtonElement> {
    icon?: React.ComponentType<IconBaseProps>;
}

const Button: React.FC<ButtonProps> = ({ children, icon: Icon, ...rest }) => (
    <Container type="button" {...rest}>
         {Icon && <Icon size={20} />}
        {children}
    </Container>
)

export default Button;