import React, { useState, useEffect, useRef, InputHTMLAttributes } from 'react';
import { useField } from '@unform/core';
import api from '../../services/api';
import { Container, Formulario } from './styles';
import { FormHandles } from '@unform/core';
import { toast } from 'react-toastify';
import { useAuth } from '../../contexts/auth';


import Input from './../Input';

interface FotoReturn {
    url: string,
    id: number,
    nome: string,
    path: string,
    updatedAt: Date,
    createdAt: Date
}

export default function AvatarInput() {
    const { user , setUser } = useAuth();

    let formRef = useRef<FormHandles>(null);
    const { defaultValue, registerField } = useField('avatar');

    const [file, setFile] = useState(defaultValue && defaultValue.id);
    const [preview, setPreview] = useState(defaultValue && defaultValue.url);

    useEffect(() => {
        setPreview(user?.usuario.foto);
    }, [])

    async function handleChange(e: any) {
        const data = new FormData();

        data.append('file', e.target.files[0]);

        const response = await api.post<FotoReturn>('fotos', data);
        const { id, url } = response.data;

        setFile(id);
        setPreview(url);

        const response2 = await api.put('/usuarios/1', {
            foto_id : id 
        })

        setUser(response2.data);
    }

    return (
        <Container>
            <label htmlFor="avatar">
                <img
                    src={
                        preview || 'https://vignette.wikia.nocookie.net/voidz/images/a/ac/ScottPhillips.png/revision/latest?cb=20180722000759'
                    }
                    alt=""
                />
                <Formulario ref={formRef} onSubmit={() => { }}  >
                    <input
                        type="file"
                        id="avatar"
                        accept="image/*"
                        onChange={handleChange}
                    />
                </Formulario>
            </label>
        </Container>
    );
}