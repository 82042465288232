import React, { useState, useEffect, useRef, useCallback, ChangeEvent } from 'react';
import { Container, Titulo, Formulario, Group, Grid, Col, Row } from './styles';
import { FormHandles } from '@unform/core';
import { toast } from 'react-toastify';

import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import Novo from '../../components/Novo';
import Input from '../../components/Input';
import Button from '../../components/Button';
import api from '../../services/api';
import Modal from '../../components/Modal';
import Dialog from '../../components/Dialog';
import Select, { SelectType, SelectDefault } from '../../components/Select';
import SubMenu from '../SubMenu';

import getValidationsErrors from '../../utils/getValidationsErrors';
import useDebounce from '../../utils/useDebounce';

import { Contratante, ContratanteReturn } from '../../model/contratante';
import IBGEUF from '../../model/ibgeUF';
import IBGECITY from '../../model/ibgeCITY';

import schema from '../../middlewares/contratante';


const Contratantes: React.FC = () => {
    let formRef = useRef<FormHandles>(null);

    const [edit, setEdit] = useState<any>({});
    const [data, setData] = useState<Contratante[]>([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage, setDataPerPage] = useState(13);
    const [selectedTipoAgrupamento, setSelectedTipoAgrupamento] = useState<SelectType>(SelectDefault);

    const [ufs, setUfs] = useState<string[]>([]);
    const [cities, setCities] = useState<string[]>([]);

    const [selectedUf, setSelectedUf] = useState<SelectType>(SelectDefault);
    const [selectedCity, setSelectedCity] = useState<SelectType>(SelectDefault);

    const [selectedOption, setSelectedOption] = useState<string>('Ativo');
    const [showModal, setShowModal] = useState(false);
    const [showDialog, setShowDialog] = useState(false);

    //Paginação 
    const indexOfLastData = currentPage * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;
    const currentDatas = data;
    const [count, setCount] = useState<number>(0);

    const [orderBy, setOrderBy] = useState<string>('id');
    const [tipoOrder, setTipoOrder] = useState<string>('Asc');
    const [page, setPage] = useState<number>(0);

    const paginate = (pageNumber: number) => setCurrentPage(pageNumber)
    const openModal = () => setShowModal(true);

    const tipoContrato: SelectType[] = [{ value: 'GRUPOTRANSPORTADOR', label: 'Grupo Transportador' },
    { value: 'MULTITRANSPORTADOR', label: 'Multi Transportador' }];

    const [filtro, setFiltro] = useState<Contratante>({} as Contratante);

    const debounce = useDebounce();

    function handleFiltroChange(event: ChangeEvent<HTMLInputElement>) {
        const { name, value } = event.target;
        debounce(() => setFiltro({ ...filtro, [name]: `%${value.toUpperCase()}%` }), 1000)
    }

    const head = {
        id: "ID",
        razaosocial: "Razão Social",
        nomefantasia: "Nome Fantasia",
        sigla: "Sigla",
        logradouro: "Logradouro",
        numero: "Número",
        complemento: "Complemento",
        bairro: "Bairro",
        cidade: "Cidade",
        uf: "UF",
        tipoagrupamento: "Tipo Agrupamento",
        ativo: "Ativo",
    };


    useEffect(() => {
        Pesquisar();
    }, [dataPerPage, currentPage, orderBy, tipoOrder, selectedOption, filtro])

    const Pesquisar = async () => {
        setLoading(true);
        const res = await api.get<ContratanteReturn>('/contratantes', {
            params: {
                page: currentPage,
                size: dataPerPage,
                orderBy: orderBy,
                tipoOrder: tipoOrder,
                ativo: selectedOption == 'Ativo' ? 1 : 0,
                filtro: { ...filtro }
            }
        });

        if (res.data && res.data.itens) {
            setData(res.data.itens);
            setCount(Number(res.data.count));
        } else {
            setData([]);
        }
        setLoading(false);
        setShowModal(false);
    }

    useEffect(() => {
        api.get<IBGEUF[]>('https://servicodados.ibge.gov.br/api/v1/localidades/estados').then(response => {
            const aa = response.data.map(uf => uf.sigla);
            setUfs(aa);
        });
    }, [page])

    useEffect(() => {
        if (selectedUf.value == undefined || selectedUf.value == null) {
            return;
        }

        api.get<IBGECITY[]>(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${selectedUf.value}/municipios`)
            .then(response => {
                const cityNames = response.data.map(city => city.nome);
                setCities(cityNames);
            });
    }, [selectedUf, page]);


    async function handleEdit(data: any) {

        setTimeout(() => {
            formRef.current?.setData(data)
        }, 200);

        setSelectedCity({ value: data.cidade, label: data.cidade });
        setSelectedUf({ value: data.uf, label: data.uf });
        const selectTipoAgru: SelectType = tipoContrato.filter(a => a.value === data.tipoagrupamento)[0];
        setSelectedTipoAgrupamento(selectTipoAgru);
        setEdit(data);

        setShowModal(true);
    };

    function handleDialog(data: any) {
        setShowDialog(true);
        setEdit(data);
    }

    async function handleInative() {
        try {
            const response = await api.put(`/contratantes/${Number(edit.id)}`, {
                ...data, ativo: false
            })

            if (response.status == 200 || response.status == 204) {
                toast.success('Contratante inativado com sucesso!');
                setEdit({});
                setShowDialog(false);
            }
        } catch (err) {
            toast.error('Falha ao inativar o registro!');
            setEdit({});
            setShowDialog(false);
        }
    }

    async function handleSubmit(data: Contratante) {
        try {
            formRef.current?.setErrors({});

            await schema.validate(data, {
                abortEarly: false
            });

            if (Number(edit.id) > 0) {

                const response = await api.put(`/contratantes/${Number(edit.id)}`, {
                    ...data
                })

                if (response.status == 200 || response.status == 204) {
                    toast.success('Contratante Alterada com sucesso!');
                    setEdit({});
                    Pesquisar();
                }

            } else {
                const response = await api.post("/contratantes", {
                    ...data, ativo: true
                })

                if (response.status == 200 || response.status == 204) {
                    toast.success('Contratante criado com sucesso!');
                    setEdit({});
                    Pesquisar();
                }
            }
        }
        catch (err) {
            if (err.toString().includes('ValidationError')) {
                const errors = getValidationsErrors(err);
                formRef.current?.setErrors(errors);
            } else {
                toast.error('Falha ao gravar, verifique os valores informados');
            }
        }
    };


    const handleSelectedTipoContrato = useCallback(async (value: any, actionMeta: any) => {
        if (value) {
            setSelectedTipoAgrupamento(value);
        } else {
            setSelectedTipoAgrupamento(SelectDefault);
        }
    }, [])

    const handleSelectedUF = useCallback(async (value: any, actionMeta: any) => {
        if (value) {
            setSelectedUf(value);
        } else {
            setSelectedUf(SelectDefault);
        }
    }, [])

    const handleSelectedCity = useCallback(async (value: any, actionMeta: any) => {
        if (value) {
            setSelectedCity(value);
        } else {
            setSelectedCity(SelectDefault);
        }
    }, [])

    function handleTipoOrder() { setTipoOrder(tipoOrder == 'Asc' ? 'Desc' : 'Asc') };

    function handleShowModal(value: boolean) {
        setShowModal(value);
        setSelectedCity(SelectDefault);
        setSelectedUf(SelectDefault);
        setSelectedTipoAgrupamento(SelectDefault);
        setEdit({});
    }


    return (
        <Container>

            <Titulo>
                <h2>Contratantes</h2>
            </Titulo>

            <Novo
                openModal={openModal}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                data={data}
                head={head}
                title="Contratantes"
            />

            <Dialog titulo="Confirma a inativação do registro?"
                showModal={showDialog}
                setShowDialog={setShowDialog}
                execute={handleInative}
            >
                <h2>Razão Social: {edit.razaosocial} </h2>
                <h2>Nome Fantasia: {edit.nomefantasia}</h2>

            </Dialog>

            <Modal titulo="Cadastro de Contratante" showModal={showModal} setShowModal={setShowModal} handleShowModal={handleShowModal} >
                    <SubMenu contratante_id={Number(edit.id)} visible={edit.id > 0} onSelect={index => setPage(index)} indexPage={page} >
                        <Formulario ref={formRef} onSubmit={handleSubmit} initialData={edit} >
                            <Grid>
                                <Row>
                                    <Col size={1}>
                                        <Input name="razaosocial" id="razaosocial" placeholder="Razão Social" />
                                    </Col>
                                    <Col size={1} >
                                        <Input name="nomefantasia" placeholder="Nome Fantasia" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col size={1} >
                                        <Input name="sigla" placeholder="Sigla" />
                                    </Col>
                                    <Col size={5} >
                                        <Input name="logradouro" placeholder="Logradouro" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col size={1} >
                                        <Input name="numero" placeholder="Número" />
                                    </Col>
                                    <Col size={3} >
                                        <Input name="complemento" placeholder="Complemento" />
                                    </Col>
                                    <Col size={2} >
                                        <Input name="bairro" placeholder="Bairro" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col size={1}>
                                        <Select
                                            options={ufs.map(p => ({ value: p, label: p }))}
                                            placeHolder="Selecione a UF"
                                            handleSelected={handleSelectedUF}
                                            selectedValue={selectedUf}
                                            name="uf"
                                        />
                                    </Col>
                                    <Col size={1}>
                                        <Select
                                            options={cities.map(p => ({ value: p, label: p }))}
                                            placeHolder="Selecione a cidade"
                                            handleSelected={handleSelectedCity}
                                            selectedValue={selectedCity}
                                            name="cidade"
                                        />
                                    </Col>
                                    <Col size={1}>
                                        <Select
                                            options={tipoContrato}
                                            placeHolder="Selecione o Agrupamento"
                                            handleSelected={handleSelectedTipoContrato}
                                            selectedValue={selectedTipoAgrupamento}
                                            name="tipoagrupamento"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col size={1}>
                                        <Input name="latitude" placeholder="Latitude" />
                                    </Col>
                                    <Col size={1} >
                                        <Input name="longitude" placeholder="Longitude" />
                                    </Col>
                                </Row>
                            </Grid>

                            <Button type="submit">Salvar</Button>

                        </Formulario>
                    </SubMenu>
            </Modal>

            <Table
                data={currentDatas}
                head={head}
                numeroTela="4"
                loading={loading}
                edit={handleEdit}
                remove={handleDialog}
                setOrderBy={setOrderBy}
                handleTipoOrder={handleTipoOrder}
                handleInputChange={handleFiltroChange}
            />

            <Pagination
                postsPerPage={dataPerPage}
                totalPosts={count}
                paginate={paginate}
            />
        </Container>

    )
}

export default Contratantes;