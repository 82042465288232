import styled from 'styled-components';

export const Container = styled.div`
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.text};
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);
`

export const Header2 = styled.div`
    height: 60px;
    display: flex;
    justify-content: space-between;
    width: 100%;
`

export const Navigation = styled.div`
    height: 32px;
    display: flex;
    /* border-bottom: 1px solid #EAEAEA;
    border-top: 1px solid #EAEAEA; */
`

export const Ul = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    text-align: center;
    list-style-type: none;
    padding: 20px;

    li {
        margin-right: 12px;
        margin-left: 12px;
    }
    a {
        color: ${props => props.theme.colors.text};
        font-size: 15px;
    }
`

export const Profile = styled.div`
  display: flex;
  margin-left: 20px;
  padding-left: 20px;
  border-left: 1px solid #eee;
  div {
    text-align: right;
    margin-right: 10px;
    strong {
      display: block;
      color: ${props => props.theme.colors.text};
    }
    a {
      display: block;
      margin-top: 2px;
      font-size: 12px;
      color: ${props => props.theme.colors.text};
    }
  }
  img {
    height: 35px;
    width: 35px;
    border-radius: 50%;
  }
`;

