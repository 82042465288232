
import styled , { css } from 'styled-components';
import { FiX } from 'react-icons/fi';


export const Background  = styled.div`
    background-color: rgba(0,0,0,0.3);
    display: flex;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
   // left: 200px;
   // top: 40px;
    color: black;
    justify-content: center;
    align-items: center;

`

export const Content = styled.div`
    background-color: ${props => props.theme.colors.background};
    width: 50vw;
    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
`

export const CloseModalButton = styled(FiX)`
    cursor: pointer;
    right: 20px;
    width: 32px;
    height: 32px;
    padding: 0;
    z-index: 10;
    color: #fff;
`;

export const Head = styled.div`
    display: flex;
    background-color: ${props => props.theme.colors.select};
    height: 55px;
    display: flex;
    justify-content: space-between;
    color: ${props => props.theme.colors.select};
    text-align: center;
    align-items: center;
    padding: 0 12px 0 12px;
    
    a {
        font-weight: 500;
        font-size: 20px;
        color: ${props => props.theme.colors.text};

    }
    
    svg {
        cursor: pointer;
        color: ${props => props.theme.colors.text};
    }

   
`
export const Body = styled.div`
    width: 100%;
    max-height: 100vh;
    margin-top: 8px;
    
    grid-template-columns: 1fr 1fr;
    position: relative;
    z-index: 10;
    border-radius: 10px;
    padding: 0 15px 18px ;

    @media (max-width: 1004px) { 

    }
`
