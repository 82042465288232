import React, { useRef, useCallback, FormEvent, ChangeEvent, useState } from 'react';
import { useAuth } from '../../contexts/auth';
import { Container, Session2, Session1, Content } from './styles'
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { FiLogIn, FiMail, FiLock } from 'react-icons/fi';
import getValidationsErrors from '../../utils/getValidationsErrors';
import * as Yup from 'yup';

import Input from '../../components/Input';
import Button from '../../components/Button';

import stopbus from '../../assets/stopbus.svg';

interface SignInFormData {
    email: string;
    password: string;
  }

const SignIn: React.FC = () => {
    const { signed, user, signIn } = useAuth();
    
    const formRef = useRef<FormHandles>(null);

    const handleSubmit = useCallback(async (data: SignInFormData) => {
        
        try {
            formRef.current?.setErrors({});
            const schema = Yup.object().shape({
                email: Yup.string().required('E-mail obrigatório').email('Digite um E-mail válido'),
                password: Yup.string().required('Senha obrigatória'),
            })

            await schema.validate(data, {
                abortEarly: false
            });
           
            signIn(data.email, data.password);

        } catch (err) {
            const errors = getValidationsErrors(err);
            formRef.current?.setErrors(errors);
        }
    }, [])

    return (
        <Container>
            <Content>
                <Session1>
                    <img src={stopbus} alt="stopbus" />
                </Session1>
                <Session2>
                   
                    <Form ref={formRef} onSubmit={handleSubmit}>
                        <h1>Faça seu logon</h1>
                        <Input name="email" icon={FiMail} placeholder="E-mail" showPlaceHolder={false} style={{ textTransform: 'none' }} />
                        <Input name="password" icon={FiLock} type="password" placeholder="Senha" showPlaceHolder={false} style={{ textTransform: 'none' }} />
                        <Button type="submit">Entrar</Button>
                    </Form>

                </Session2>
            </Content>
        </Container>
    );
}

export default SignIn;
