import React, { useState, useRef, useEffect, useCallback, Dispatch, Children } from 'react';
import { useSpring, animated } from 'react-spring';
import { Background, Content, Head, Body, CloseModalButton } from './styles';

interface PropsModal {
    titulo?: string;
    showModal: boolean;
    setShowModal: Dispatch<boolean>;
    handleShowModal(value : boolean) : void;
}

const Modal: React.FC<PropsModal> = ({ titulo, showModal, setShowModal, handleShowModal, children }) => {
    const modalRef = useRef();

    const closeModal = (e: MouseEvent) => {
        if (modalRef.current === e.target) {
            handleShowModal(false);
        }
    };

    const keyPress = useCallback(
        e => {
            if (e.key === 'Escape' && showModal) {
                handleShowModal(false);
            }
        },
        [setShowModal, showModal]
    );


    useEffect(
        () => {
            document.addEventListener('keydown', keyPress);
            return () => document.removeEventListener('keydown', keyPress);
        },
        [keyPress]
    );


    const animation = useSpring({
        config: {
            duration: 250
        },
        opacity: showModal ? 1 : 0,
        transform: showModal ? `translateY(0%)` : `translateY(-100%)`
    });

    return (
        <>
            { showModal ? (
                <Background >
                    <animated.div style={animation}>
                        <Content>

                            <Head>
                                <a>{titulo}</a>
                                <CloseModalButton
                                    onClick={() => handleShowModal(false)}
                                />
                            </Head>

                            <Body>
                                {children}
                            </Body>
                        </Content>
                    </animated.div>
                </Background >
            ) : null
            }
        </>

    );
}

export default Modal;