import React, { useEffect, useState } from 'react';

import { Nav } from './styles';


interface PropsPagination {
    postsPerPage: number;
    totalPosts: number;
    paginate(pageNumber: number): void;
}

const Pagination: React.FC<PropsPagination> = ({ postsPerPage, totalPosts, paginate }) => {
    const pageNumbers = [];
    const [selectedItems, setSelectedItems] = useState<number[]>([]);

    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pageNumbers.push(i);
    }

    function handleSelectItem(id: number) {
        paginate(id);
        setSelectedItems([id]);
    }

    return (
        <Nav>
            <ul>
                {pageNumbers.map(number => (
                    <li
                        key={number}
                        onClick={() => { handleSelectItem(number) }}
                        className={selectedItems.includes(number) ? 'active' : ''}
                    >
                        <a>
                            {number}
                        </a>
                    </li>

                ))}
            </ul>
            { totalPosts > 0 &&
            <p>TOTAL {totalPosts} </p>
            }
        </Nav>
    );
}

export default Pagination;