import { HexBase64BinaryEncoding } from 'crypto';
import React , { Dispatch , useCallback , useEffect, useRef , useState } from 'react';
import { SwatchesPicker } from 'react-color';

import { Container, Head, CloseModalButton , Body } from './styles';


import { useAuth } from '../../contexts/auth';



interface Props {
    showModal?: boolean;
    setShowModal: Dispatch<boolean>;
    toggleTheme(): void;
    AtualizaTheme(color: string): void;
}

const ColorPicker: React.FC<Props> = ({ toggleTheme, AtualizaTheme , showModal , setShowModal }) => {
    const modalRef = useRef();
    const { color, setColor } = useAuth();


     const closeModal = (e: MouseEvent) => {
        if (modalRef.current === e.target) {
            setShowModal(!showModal);
        }
    };

    const keyPress = useCallback(
        e => {
            if (e.key === 'Escape' && showModal) {
                setShowModal(!showModal);
                console.log('I pressed');
            }
        },
        [setShowModal, showModal]
    );


    useEffect(
        () => {
            document.addEventListener('keydown', keyPress);
            return () => document.removeEventListener('keydown', keyPress);
        },
        [keyPress]
    );

   

    async function handleChangeComplete(color: any)  {
        await localStorage.setItem('@RNColor', color.hex);
        
        await AtualizaTheme(color.hex);
    };

    return (
        <>
            { showModal ? (
                <Container>
                    <Head >
                        <a>Selecione a cor</a>
                        <CloseModalButton
                            onClick={() => setShowModal(!showModal)}
                        />
                    </Head>
                    <Body>
                        <SwatchesPicker
                            onChange={handleChangeComplete}
                        />
                    </Body>
                </Container>
            ) : null
            }
        </>)
}

export default ColorPicker;