import React from 'react';
import { Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

import { ThemeProvider } from 'styled-components';
import GlobalStyles from './styles/global';  

import light from './styles/themes/light';
import dark from './styles/themes/dark';

import Routes from './routes'
import { AuthProvider } from './contexts/auth';
import history from './services/history';
import usePersistedState from './utils/usePersitedState';
import { DefaultTheme } from 'styled-components';
import Header from './components/Header';


const App: React.FC = (props) => {
  const [theme, setTheme] = usePersistedState<DefaultTheme>('theme', light);

  async function toggleTheme() {
    const color = await localStorage.getItem('@RNColor')!;
    light.colors.secundary = color === null ? '#01579b' : color;
    dark.colors.secundary = color === null ? '#01579b' : color;
    setTheme(theme.title == 'light' ? dark: light)
  }

  async function AtualizaTheme(color: string)  {
    light.colors.secundary = `${color}`;
    dark.colors.secundary = `${color}`;

    if(theme.title == 'light') {
      await localStorage.setItem('theme', JSON.stringify(light));
      await setTheme(dark);
      await setTheme(light);
    } else {
      await localStorage.setItem('theme', JSON.stringify(dark));
      await setTheme(light);
      await setTheme(dark);
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <GlobalStyles />
        <Router history={history} >
          <Routes>
          <Header toggleTheme={toggleTheme} AtualizaTheme={AtualizaTheme} />
            
          </Routes>
          {props.children}
          <ToastContainer autoClose={3000} />
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
