import styled, { css } from 'styled-components';
import { shade } from 'polished';
import light from '../../styles/themes/light'


interface Props {
    size: boolean;
}

export const Content = styled.div<Props>`
    z-index: 20;
    margin-left: 4vw;

    transition: 0.5s;
    ${props => props.size && css`
        margin-left: 10vw;
        transition: 0.5s;
    `};
  
`

export const Button = styled.div<Props>`
    display: flex;
    justify-content: center;
    padding: 5px;
    

    button {
        width: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 38px;
        border: 0;
        background-color: ${props => props.theme.colors.secundary};
        color: ${props => props.theme.colors.primary};
        border-radius: 4px;

        float: right;
        transform: rotate(0deg);
        overflow: hidden;
        transition: all 0.3s ease-out;
        transform: ${props => (props.size ? `rotate(180deg); height: 32px` : "")};

        &:hover {  
            background: ${props => shade(0.2, props.theme.colors.secundary)};
            border: 0;
        }    

        svg {
            color: #ccc;
        }

    }
        

`

export const Nav = styled.nav`
/* nav */
   
`


export const Ul = styled.ul<Props>`
    wra

    li {
        font: 13px Montserrat;

        height: 36px;
        line-height: 28px;
        list-style: none;
        cursor: pointer;
      
        text-align: -webkit-match-parent;
        font-weight: 500;
        color: #F0F4F6;
        align-items: center;
        
        display: flex;
        flex-direction: row;
        width: 100%;
        
        ${props => !props.size && css`
                height: 50px;
        `};

        ${props => !props.size && css`
             justify-content: center;
        `};
        
        
        a {
         //   height: 46px;
            font-weight: 500;
            font-size: 12px;
            color: #F0F4F6;
            transition: 0.5s;
            padding-left: 10px;

            ${props => !props.size && css`
                font-weight: 500;
                font-size: 14px;
                 transition: 0.5s;
                 padding-left: 0px;
            `};
            
        }
        
        &:hover {
            background-color: ${shade(0.3, '#0000')};
        }
    }

        .has-children {
            position: relative;
            &:last-child {
                position: absolute;
                margin-top: 17px;
                left: 0;
                width: 100%;
                //display: block;
            }
        }
`

export const Container = styled.div<Props>`
    background-color: ${props => props.theme.colors.secundary};
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    padding-top: 60px;
    /* overflow-y: scroll; */
    width: 10vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    

    max-width: 4vw;
    transition: 0.5s;
    ${props => props.size && css`
        max-width: 14vw;
        transition: 0.5s;
    `};

    z-index: 20;

   
`;

export const Main = styled.div`
   

    .nav-menu,.nav-menu ul,.nav-menu li,.nav-menu a { margin: 0; padding: 0; line-height: normal; list-style: none; display: block; position: relative; }
    .nav-menu ul { opacity: 0; position: absolute; top: 100%; left: -9999px; z-index: 999; -webkit-transition: opacity .3s; transition: opacity .3s; }
    .nav-menu li:hover > ul { left: 0; opacity: 1; z-index: 1000; }
    .nav-menu ul li:hover > ul { top: 0; left: 100%; }
    .nav-menu li { cursor: default; float: left; white-space: nowrap; }
    .nav-menu ul li { float: none; }

    /* sub width */
    .nav-menu ul { min-width: 12em; -webkit-box-shadow: 2px 2px 2px rgba(0,0,0,0.25); box-shadow: 2px 2px 2px rgba(0,0,0,0.25); }

    /* center */
    .nav-center { float: right; right: 50%; }
    .nav-center > li { left: 50%; }

    /* root */
    .nav-menu a {
    padding: 0 10px;
    color: #5BC0DE;
    font-weight: normal;
    font-size: 16px;
    line-height: 40px;
    text-decoration: none;
    }

    /* root: active */
    .nav-menu > li > .nav-active { background-color: rgba(0,0,0,0.05); }

    /* root: hover/persistence */
    .nav-menu a:hover,.nav-menu a:focus,.nav-menu li:hover a {

    background: ${props => shade( 0.2 , props.theme.colors.secundary)};
    color: #FFF;
    }

    /* 2 */
    .nav-menu li li a,.nav-menu li:hover li a {
    padding: 8px 10px;
    background: ${props => shade( 0.1 , props.theme.colors.secundary)};
    color: #FFF;
    font-size: 12px;
    line-height: normal;
    }

    /* 2: hover/persistence */
    .nav-menu li:hover li a:hover,.nav-menu li:hover li a:focus,.nav-menu li:hover li:hover a {
    background:  ${props => shade( 0.4 , props.theme.colors.secundary)};;
    }

    /* 3 */
    .nav-menu li:hover li:hover li a {
    background: #444;
    }

    /* 3: hover/persistence */
    .nav-menu li:hover li:hover li a:hover,.nav-menu li:hover li:hover li a:focus,.nav-menu li:hover li:hover li:hover a {
    background: #333;
    }

    /* 4 */
    .nav-menu li:hover li:hover li:hover li a {
    background: #333;
    }

    /* 4: hover */
    .nav-menu li:hover li:hover li:hover li a:hover,.nav-menu li:hover li:hover li:hover li a:focus {
    background: #222;
    }

    /* vertical */
    .nav-vertical { max-width: 220px; }
    .nav-vertical ul { top: 0; left: -9999px; }
    .nav-vertical li { width: 100%; float: none; }
    .nav-vertical li:hover > ul { left: 100%; }
`;

export const Container2 = styled.div`

    * { margin: 0; border: 0; padding: 0; font-size: 90%; }

    a { color: #428BCA; text-decoration: none; }
    a:hover,a:focus { color: #999; text-decoration: none; outline: 0; }

    h1,h2,h3,h4,h5,h6 { margin: 0 0 20px; font-size: 30px; line-height: 1.1; }
    h2 { font-size: 14px; }
    h3 { border-bottom: 1px solid #DDD; padding: 0 0 10px; font-size: 15px; }
    h4 { font-size: 20px; }
    h5 { font-size: 18px; }
    h6 { font-size: 16px; }

    p,ul,ol,table { margin: 0 0 20px; }
    ul,ol { margin: 0 0 20px 25px; }
    ul ul,ul ol,ol ol,ol ul{ margin-bottom: 0; }
    table table { margin-bottom: 0; }
    hr { margin: 0 0 19px; border: 0 none; border-top: 1px solid #DDD; height: 0; background: transparent; color: transparent; -webkit-box-sizing: border-box; box-sizing: border-box; }
    nav { margin: 0 0 20px; }
    nav:before,nav:after { content: " "; display: table; }
    nav:after { clear: both; }

    .container { margin: 0 auto; max-width: 940px; }
`