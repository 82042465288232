export default {
    title: 'light',

    colors: {
        primary: '#FFFFFF',
        secundary:  '#01579b',
        background: '#FAFAFA',
        text: '#333',
        select: '#EAEAEA',
        border: '#EAEAEA'
    },
}