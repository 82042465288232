import * as Yup from 'yup';

const schema = Yup.object().shape({
    nome: Yup.string()
        .min(3)
        .max(100)
        .required('Nome é Obrigatório'),
    horario_chegada: Yup.string()
        .required('Horário Chegada é Obrigatório'),
    horario_saida: Yup.string()
    .required('Horário Saída é Obrigatório')
});

export default schema;