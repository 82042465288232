import React, { useState, Dispatch, useCallback , useRef, useEffect } from 'react';
import { useField } from '@unform/core';


import { Container , Select2 } from './styles';

interface PropsSelect {
  options: any[];
  placeHolder: string;
  selectedValue: SelectType;
  handleSelected(value: any, actionMeta: any): void;
  name: string;
}

export interface SelectType {
  value: string;
  label: string;
}

export const SelectDefault = {
    label: 'Selecione',
    value: ''
}

const SelectOpt: React.FC<PropsSelect> = 
({ options, placeHolder, selectedValue, handleSelected , name  ,...rest }) => {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  
  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    setIsFilled(!!selectRef.current)
}, [])

useEffect(() => {
  registerField({
    name: fieldName,
    ref: selectRef.current,
    getValue: (ref: any) => {
      
      if (!ref.state.value) {
        return '';
      }
      return ref.state.value.value;
    },
  });
}, [fieldName, registerField ]);


const customStyles = {
  option: (provided: any) => ({
    ...provided,
    color: 'black'
  }),
  control: (provided: any) => ({
    ...provided,
    color: 'black'
  })
}
  return (
    <Container
      isFocused={isFocused}
      isFilled={isFilled}
    >
      <label htmlFor="">{placeHolder}</label>
      <Select2
        options={options}
        onChange={handleSelected}
        className="basic-single"
        isClearable={false}
        placeholder={placeHolder}
        value={selectedValue}
        onBlur={handleInputBlur}
        onFocus={() => setIsFocused(true)}
        name={name}
        ref={selectRef}
        ClassName="teste"
        {...rest}
        
      />
    </Container>

  );
}

export default SelectOpt;