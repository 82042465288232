import React, { useState, useEffect, useRef, useCallback, ChangeEvent } from 'react';
import { Container, Titulo, Formulario, Group, Grid, Row, Col } from './styles';
import { FormHandles } from '@unform/core';
import { toast } from 'react-toastify';

import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import Novo from '../../components/Novo';
import Input from '../../components/Input';
import Button from '../../components/Button';
import api from '../../services/api';
import Modal from '../../components/Modal';
import Dialog from '../../components/Dialog';
import Select, { SelectDefault, SelectType } from '../../components/Select';

import getValidationsErrors from '../../utils/getValidationsErrors';
import useDebounce from '../../utils/useDebounce';

import { Usuario, UsuarioReturn } from '../../model/usuario';
import { Perfil, PerfilReturn } from '../../model/perfil';

import schema from '../../middlewares/usuario';

interface Props {
    ocultarTitulo?: boolean;
    transportadora_id?: number;
    contratante_id?: number;
}

const Usuarios: React.FC<Props> = ({ ocultarTitulo, transportadora_id, contratante_id }) => {
    let formRef = useRef<FormHandles>(null);

    const [edit, setEdit] = useState<any>({});
    const [data, setData] = useState<Usuario[]>([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage, setDataPerPage] = useState(13);

    const [perfil, setPerfil] = useState<Perfil[]>([]);
    const [selectedPerfil, setSelectedPerfil] = useState<SelectType>(SelectDefault);

    const [showModal, setShowModal] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    //Paginação 
    const [selectedOption, setSelectedOption] = useState<string>('Ativo');
    const indexOfLastData = currentPage * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;
    const currentDatas = data;
    const [count, setCount] = useState<number>(0);

    const [orderBy, setOrderBy] = useState<string>('id');
    const [tipoOrder, setTipoOrder] = useState<string>('Asc');

    const paginate = (pageNumber: number) => setCurrentPage(pageNumber)
    const openModal = () => setShowModal(true);

    const [filtro, setFiltro] = useState<Usuario>({} as Usuario);

    const debounce = useDebounce();

    function handleFiltroChange(event: ChangeEvent<HTMLInputElement>) {
        const { name, value } = event.target;
        debounce(() => setFiltro({ ...filtro, [name]: `%${value.toUpperCase()}%` }), 1000)
    }

    const head = {
        id: "ID",
        nome: "Nome",
        email: "E-mail",
        descricaoPerfil: "Perfil",
        ativo: "Ativo",
    };


    useEffect(() => {
        Pesquisar();
    }, [dataPerPage, currentPage, orderBy, tipoOrder, selectedOption, filtro])

    const Pesquisar = async () => {
        setLoading(true);
        const res = await api.get<UsuarioReturn>('/usuarios', {
            params: {
                page: currentPage,
                size: dataPerPage,
                orderBy: orderBy,
                tipoOrder: tipoOrder,
                ativo: selectedOption === 'Ativo' ? true : false,
                transportadora_id,
                contratante_id,
                filtro: {
                    ...filtro,
                }
            }
        });

        if (res.data && res.data.itens) {
            const teste = res.data.itens.map(i => ({
                ...i,
                descricaoPerfil: i.perfil.descricao!
            }))

            setData(teste);
            setCount(Number(res.data.count));
        } else {
            setData([]);
        }
        setLoading(false);
        setShowModal(false);
    }

    useEffect(() => {
        const PesquisarMarcas = async () => {
            const res = await api.get<PerfilReturn>('/perfil', {
                params: {
                    filtro: {}
                }
            });

            if (res.data && res.data.itens) {
                setPerfil(res.data.itens);
            } else {
                setPerfil([]);
            }
        }
        PesquisarMarcas();
    }, [])

    async function handleEdit(data: any) {

        setTimeout(() => {
            formRef.current?.setData(data)
        }, 200);

        setSelectedPerfil({
            value: data.perfil.id,
            label: data.perfil.descricao
        });

        setEdit(data);
        setShowModal(true);
    };

    function handleDialog(data: any) {
        setShowDialog(true);
        setEdit(data);
    }

    async function handleInative() {
        try {
            const response = await api.put(`/usuarios/${Number(edit.id)}`, {
                ...data, ativo: false
            })

            if (response.status === 200 || response.status === 204) {
                toast.success('Usuário Inativado com sucesso!');
                setEdit({});
                setShowDialog(false);
            }
        } catch (err) {
            toast.error('Falha ao inativar o registro!');
            setEdit({});
            setShowDialog(false);
        }
    }

    async function handleSubmit(data: Usuario) {
        try {
            formRef.current?.setErrors({});

            await schema.validate(data, {
                abortEarly: false
            });

            if (Number(edit.id) > 0) {

                const response = await api.put(`/usuarios/${Number(edit.id)}`, {
                    ...data, contratante_id, transportadora_id
                })

                if (response.status == 200 || response.status == 204) {
                    toast.success('Usuário Alterado com sucesso!');
                    setEdit({});
                    Pesquisar();
                }

            } else {
                const response = await api.post("/usuarios", {
                    ...data, ativo: true, contratante_id, transportadora_id
                })

                if (response.status == 200 || response.status == 204) {
                    toast.success('Usuário criado com sucesso!');
                    setEdit({});
                    Pesquisar();
                }
            }
        }
        catch (err) {

            if (err.toString().includes('ValidationError')) {
                const errors = getValidationsErrors(err);
                formRef.current?.setErrors(errors);
            } else {
                toast.error('Falha ao gravar, verifique os valores informados');
            }
        }
    };

    function handleTipoOrder() { setTipoOrder(tipoOrder == 'Asc' ? 'Desc' : 'Asc') };

    function handleShowModal(value: boolean) {
        setShowModal(value);
        setSelectedPerfil(SelectDefault);
        setEdit({});
    }

    const handleSelectPerfil = useCallback(async (value: any, actionMeta: any) => {
        if (value) {
            setSelectedPerfil(value);
        } else {
            setSelectedPerfil(SelectDefault);
        }
    }, [])

    const options: any[] = [];

    perfil.forEach(p => {
        options.push({ value: p.id, label: p.descricao });
    });

    return (
        <Container>

            { !ocultarTitulo && (
                <Titulo>
                    <h2>Usuários</h2>
                </Titulo>
            )
            }

            <Novo
                openModal={openModal}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                data={data}
                head={head}
                title="Usuários"
            />

            <Dialog titulo="Confirma a inativação do registro?"
                showModal={showDialog}
                setShowDialog={setShowDialog}
                execute={handleInative}
            >
                <h2>ID: {edit.id} </h2>
                <h2>Descrição: {edit.descricao}</h2>

            </Dialog>

            <Modal titulo="Cadastro de Usuário" showModal={showModal} setShowModal={setShowModal} handleShowModal={handleShowModal} >
                <Formulario ref={formRef} onSubmit={handleSubmit}  >

                    <Grid>
                        <Row>
                            <Col size={1}>
                                <Input name="nome" id="nome" placeholder="Nome" style={{ textTransform: 'none' }} />
                            </Col>
                            <Col size={1} >
                                <Input name="email"  type="email" placeholder="E-mail" style={{ textTransform: 'none' }} />
                            </Col>
                        </Row>
                        <Row>
                            <Col size={1} >
                                <Input name="senha" type="password" placeholder="Digite sua Senha" style={{ textTransform: 'none' }} />
                            </Col>
                            <Col size={1} >
                                <Input name="confirma_senha" type="password" placeholder="Confirme sua senha" style={{ textTransform: 'none' }} />
                            </Col>
                        </Row>
                        <Row>
                            <Col size={1} >
                                <Select
                                    options={options}
                                    placeHolder="Selecione o Perfil"
                                    handleSelected={handleSelectPerfil}
                                    selectedValue={selectedPerfil}
                                    name="perfil_id"
                                />
                            </Col>
                        </Row>
                    </Grid>


                    <Button type="submit">Salvar</Button>
                </Formulario>
            </Modal>

            <Table
                data={currentDatas}
                head={head}
                numeroTela="2"
                loading={loading}
                edit={handleEdit}
                remove={handleDialog}
                setOrderBy={setOrderBy}
                handleTipoOrder={handleTipoOrder}
                handleInputChange={handleFiltroChange}
            />

            <Pagination
                postsPerPage={dataPerPage}
                totalPosts={count}
                paginate={paginate}
            />
        </Container>
    )
}

export default Usuarios;
