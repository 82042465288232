export const treeData = [
    
    {
        key: "1",
        label: "Transportadoras",
        tipo: 'Administrador',
        children: null
    },
    {
        key: "2",
        label: "Contratantes",
        children: null
    },
    {
        key: "3",
        label: "Motoristas",
        children: null
    },
    {
        key: "4",
        label: "Rastreadores",
        children: null
    },
    {
        key: "5",
        label: "Tipos_Colaboradores",
        children: null
    },
    {
        key: "6",
        label: "Contatos",
        children: null
    },
    {
        key: "7",
        label: "Perfil",
        children: null
    },
    {
        key: "8",
        label: "Usuários",
        tipo: 'Administrador',
        children: null
    },
    {
        key: "9",
        label: "Turnos",
        children: null
    },
    
    {
        key: "10",
        label: "Veículos",
        title: "Veículos Folder",
        children: [
            {
                key: "0-1",
                label: "Marcas",
                children: null
            },
            {
                key: "0-2",
                label: "Modelos",
                children: null
            },
            {
                key: "0-3",
                label: "Tipos_Veículos",
                children: null
            }
        ]
    },
    {
        key: "7",
        label: "Identificadores",
        title: "Transportadoras Folder",
        children: null
    }
   
]




 // const data = [{ id: 1, name: 'Transportadoras', parent: null },
    // { id: 2, name: 'Contratantes', parent: null },
    // { id: 3, name: 'Motoristas', parent: null },
    // { id: 4, name: 'Rastreadores', parent: null },
    // { id: 5, name: 'Tipos_Colaboradores', parent: null },
    // { id: 6, name: 'Veículos', parent: null },
    // { id: 7, name: 'Marcas', parent: 6 },
    // { id: 8, name: 'Modelos', parent: 6 },
    // { id: 9, name: 'Tipos_Veículos', parent: 6 },
    // { id: 10, name: 'Contatos', parent: null }]