
import styled , { css } from 'styled-components';
import { FiX } from 'react-icons/fi';
import Button from '../Button';
import { shade } from 'polished';



export const Background  = styled.div`
    background-color: rgba(0,0,0,0.1);
    display: flex;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 99999;
   // left: 200px;
   // top: 40px;
    color: black;
    justify-content: center;
    align-items: center;

`

export const Content = styled.div`
    background-color: ${props => props.theme.colors.primary};
    width: 850px;
    height: 90%;
    max-height: 1000px;
    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
    display: grid;

`

export const CloseModalButton = styled(FiX)`
    cursor: pointer;
    right: 20px;
    width: 32px;
    height: 32px;
    padding: 0;
    z-index: 10;
    color: #fff;
`;

export const Head = styled.div`
    display: flex;
    background-color: ${props => props.theme.colors.select};
    height: 55px;
    display: flex;
    justify-content: space-between;
    color: ${props => props.theme.colors.select};
    text-align: center;
    align-items: center;
    padding: 0 12px 0 12px;
    

    a {
        font-weight: 500;
        font-size: 20px;
        color: #333;
    }
    svg {
        cursor: pointer;
        color: #333;
    }
`
export const Body = styled.div`
    width: 100%;
    height: 80%;
    margin-top: 8px;
    
    grid-template-columns: 1fr 1fr;
    position: relative;
    z-index: 10;
    border-radius: 10px;
    padding:12px ;
`
export const Footer = styled.div`
    display: flex;
    width: 100%;
    height: 10%;
    padding: 12px;

    div {
        margin-right: 5px;
        width: 50%;
        background-color: red;
    }
`

export const ButtonCancelar = styled(Button)`
    background-color: #ff6961;

    &:hover {
        background: ${shade(0.2, '#ff6961')};
    }
`
