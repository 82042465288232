import * as Yup from 'yup';

// concluir

const schema = Yup.object().shape({
    apelido: Yup.string()
        .min(3)
        .max(100)
        .required('Apelido é Obrigatório'),
    nomefantasia: Yup.string()
        .min(3)
        .max(100)
        .required('Nome Fantasia é Obrigatório'),
    sigla: Yup.string()
        .min(3, 'mínimo 3 caracteres')
        .max(10,'max 10 caracteres'),
    logradouro: Yup.string()
        .min(5, 'mínimo 5 caracteres')
        .max(100,'max 100 caracteres'),
    numero: Yup.string(),
    complemento: Yup.string()
        .min(5, 'mínimo 5 caracteres')
        .max(100,'max 100 caracteres'),
    bairro: Yup.string()
        .min(3, 'mínimo 3 caracteres')
        .max(50,'max 50 caracteres'),
    cidade: Yup.string()
        .min(3, 'mínimo 3 caracteres')
        .max(50,'max 50 caracteres'),
    uf: Yup.string()
        .required('UF é Obrigatório'),
    tipoagrupamento: Yup.string().required(),
    latitude: Yup.string(),
    longitude: Yup.string()
});

export default schema;