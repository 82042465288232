import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    height: 60px;
    justify-content: flex-end;
    align-items: center;

    > div {
        width: 120px;

        button {
            margin-top: 0px;
        }
    }
`

export const Form = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: -35px;

    div {
        display: flex;
        flex-direction: column;
        flex: 1;

        label {
            display: flex;
            cursor: pointer;
            input {
                margin-right: 4px;
                cursor: pointer;
            }
        }
    }
`
