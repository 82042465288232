export default {
    
    title: 'dark',


    colors: {
        primary: '#424242',
        secundary: '#01579b',
        background: '#303030',
        text: '#c9d1d9',
        select: '#383838',
        border: 'transparent'
    },
}
