import React, { useState, Dispatch, useEffect } from 'react';
import { GrNext } from 'react-icons/gr';
import Sigla from '../../utils/sigla'
import { treeData } from './treeData';
import { useHistory, Link } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';

import { Container, Content, Button, Ul, Nav, Main, Container2 } from './styles';

interface Three {
    id: number;
    name: string;
    tipo?: string;
    parent: number;
}

const SideMenu: React.FC = ({ children }) => {
    const [size, setSize] = useState<boolean>(true);
    const [color, setColor] = useState<string>('');

    useEffect(() => {
        setColor(localStorage.getItem('@RNColor')!);
    }, [])

    return (
        <Content size={size}>
            <Container size={size}>
                {
                    <Container2>
                        <Main>
                            <nav>
                                <ul className="nav-menu  nav-vertical">
                                    <Tree data={treeData} size={size} />
                                </ul>
                            </nav>
                        </Main>
                    </Container2>
                }
                <div>
                    <Button size={size}>
                        <button onClick={() => setSize(!size)} >
                            <GrNext size={20} />
                        </button>
                    </Button>
                </div>
            </Container>
            { children}
        </Content>
    );
}

type TreeProps = {
    data: any[];
    size: any;
}

const Tree: React.FC<TreeProps> = ({ data, size }) => {
    const { user } = useAuth();
    return (

        <>
            {data.map(tree => {
                if (tree.tipo) {
                    if (tree.tipo != 'Administrador') {
                        return <TreeNode node={tree} size={size} />
                    }
                } else {
                    return <TreeNode node={tree} size={size} />
                }
                if (tree.tipo === user?.usuario?.tipo) {
                    return <TreeNode node={tree} size={size} />
                }
            })}
        </>
    )
}

const TreeNode: React.FC<any> = ({ node, size }) => {
    const [childVisible, setChildVisible] = useState<boolean>(false);

    const hasChild = node.children ? true : false;

    return (

        <Main>

            <li onMouseOver={e => setChildVisible(true)} 
                onMouseLeave={e => setChildVisible(false)} 
                className={hasChild ? 'nav-active' : ''}  >
                <Link 
                        to={`/${node.label.toLowerCase()
                        .replace("_", "")
                        .replace("í", "i")
                        .replace("á", "a")}`}
                    style={{ textDecoration: 'none', display: 'flex', width: '100%' }}  >

                <div style={{
                    display: 'flex', justifyContent: size ? 'space-between' : 'center', width: '90%'
                    , alignItems: 'center'
                }}>
                    {size ? (node.label.replace("_", " ")) : Sigla(node.label)}
                </div>

                </Link>
                {
                    hasChild && childVisible && <div>
                        <Main>
                            <Tree data={node.children} size={size} />
                        </Main>
                    </div>
                }
            </li>



        </Main>
    )
}

export default SideMenu;