import React, { useState, useEffect, useRef, useCallback, ChangeEvent } from 'react';
import { Container, Titulo, Formulario, Grid, Col, Row } from './styles';
import { FormHandles } from '@unform/core';
import { toast } from 'react-toastify';

import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import Novo from '../../components/Novo';
import Input from '../../components/Input';
import Button from '../../components/Button';
import api from '../../services/api';
import Modal from '../../components/Modal';
import Dialog from '../../components/Dialog';

import getValidationsErrors from '../../utils/getValidationsErrors';
import useDebounce from '../../utils/useDebounce';

import { Turno, TurnoReturn } from '../../model/turno';

import schema from '../../middlewares/turno';

const Turnos: React.FC = () => {
    let formRef = useRef<FormHandles>(null);

    const [edit, setEdit] = useState<any>({});
    const [data, setData] = useState<Turno[]>([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage, setDataPerPage] = useState(13);

    const [showModal, setShowModal] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    //Paginação 
    const [selectedOption, setSelectedOption] = useState<string>('Ativo');
    const indexOfLastData = currentPage * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;
    const currentDatas = data;
    const [count, setCount] = useState<number>(0);

    const [orderBy, setOrderBy] = useState<string>('id');
    const [tipoOrder, setTipoOrder] = useState<string>('Asc');

    const paginate = (pageNumber: number) => setCurrentPage(pageNumber)
    const openModal = () => setShowModal(true);

    const [filtro, setFiltro] = useState<Turno>({} as Turno);

    const debounce = useDebounce();

    function handleFiltroChange(event: ChangeEvent<HTMLInputElement>) {
        const { name, value } = event.target;
        debounce(() => setFiltro({ ...filtro, [name]: `%${value.toUpperCase()}%` }), 1000)
    }

    const head = {
        id: "ID",
        nome: "Nome",
        horario_chegada: "Horário chegada",
        horario_saida: "Horário saída",
        ativo: "Ativo",
    };


    useEffect(() => {
        Pesquisar();
    }, [dataPerPage, currentPage, orderBy, tipoOrder, selectedOption, filtro])

    const Pesquisar = async () => {
        setLoading(true);
        const res = await api.get<TurnoReturn>('/turnos', {
            params: {
                page: currentPage,
                size: dataPerPage,
                orderBy: orderBy,
                tipoOrder: tipoOrder,
                ativo: selectedOption === 'Ativo' ? true : false,
                filtro: { ...filtro }
            }
        });

        if (res.data && res.data.itens) {
            setData(res.data.itens);
            setCount(Number(res.data.count));
        } else {
            setData([]);
        }
        setLoading(false);
        setShowModal(false);
    }

    async function handleEdit(data: any) {

        setTimeout(() => {
            formRef.current?.setData(data)
        }, 200);

        setEdit(data);
        setShowModal(true);
    };

    function handleDialog(data: any) {
        setShowDialog(true);
        setEdit(data);
    }

    async function handleInative() {
        try {
            const response = await api.put(`/turnos/${Number(edit.id)}`, {
                ...data, ativo: false
            })

            if (response.status === 200 || response.status === 204) {
                toast.success('Turno Inativado com sucesso!');
                setEdit({});
                setShowDialog(false);
            }
        } catch (err) {
            toast.error('Falha ao inativar o registro!');
            setEdit({});
            setShowDialog(false);
        }
    }

    async function handleSubmit(data: Turno) {
        try {
            formRef.current?.setErrors({});

            await schema.validate(data, {
                abortEarly: false
            });

            if (Number(edit.id) > 0) {

                const response = await api.put(`/turnos/${Number(edit.id)}`, {
                    ...data
                })

                if (response.status == 200 || response.status == 204) {
                    toast.success('Turno Alterado com sucesso!');
                    setEdit({});
                    Pesquisar();
                }

            } else {
                const response = await api.post("/turnos", {
                    ...data, ativo: true
                })

                if (response.status == 200 || response.status == 204) {
                    toast.success('Turno Criado com sucesso!');
                    setEdit({});
                    Pesquisar();
                }
            }
        }
        catch (err) {

            if (err.toString().includes('ValidationError')) {
                const errors = getValidationsErrors(err);
                formRef.current?.setErrors(errors);
            } else {
                toast.error('Falha ao gravar, verifique os valores informados');
            }
        }
    };

    function handleTipoOrder() { setTipoOrder(tipoOrder == 'Asc' ? 'Desc' : 'Asc') };

    function handleShowModal(value: boolean) {
        setShowModal(value);
        setEdit({});
    }

    return (
        <Container>

            <Titulo>
                <h2>Turnos</h2>
            </Titulo>

            <Novo
                openModal={openModal}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                data={data}
                head={head}
                title="Turnos"
            />

            <Dialog titulo="Confirma a inativação do registro?"
                showModal={showDialog}
                setShowDialog={setShowDialog}
                execute={handleInative}
            >
                <h2>ID: {edit.id} </h2>
                <h2>Descrição: {edit.nome}</h2>

            </Dialog>

            <Modal titulo="Cadastro de Turno" showModal={showModal} setShowModal={setShowModal} handleShowModal={handleShowModal} >
                <Formulario ref={formRef} onSubmit={handleSubmit}  >


                    <Grid>
                        <Row>

                            <Col size={1} >
                                <Input name="nome" placeholder="Nome do turno" />
                            </Col>
                        </Row>
                        <Row>
                            <Col size={1} >
                                <Input name="horario_chegada" placeholder="Horário chegada" />
                            </Col>
                            <Col size={1} >
                                <Input name="horario_saida" placeholder="Horário saída" />
                            </Col>
                        </Row>
                    </Grid>


                    <Button type="submit">Salvar</Button>
                </Formulario>
            </Modal>

            <Table
                data={currentDatas}
                head={head}
                numeroTela="13"
                loading={loading}
                edit={handleEdit}
                remove={handleDialog}
                setOrderBy={setOrderBy}
                handleTipoOrder={handleTipoOrder}
                handleInputChange={handleFiltroChange}
            />

            <Pagination
                postsPerPage={dataPerPage}
                totalPosts={count}
                paginate={paginate}
            />
        </Container>
    )
}

export default Turnos;