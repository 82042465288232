import styled , { css } from 'styled-components';

export const Nav = styled.div`

    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    padding-left: 8px;
    padding-right: 24px;


    ul {
        display: flex;
        flex-direction: row;
        color: ${props => props.theme.colors.text};
        list-style-type: none;
        margin-top: 25px;

        li {
            &:first-child {
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }
            &:last-child {
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }

            border: 1px solid ${props => props.theme.colors.select};
            width: 32px;
            height: 25px;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;

            &:hover {
                background-color: #ccc;
                cursor: pointer;
            }

            &.active
            {
                background-color: #ccc;
            }
        }
    }

    P {
        margin-bottom: 2px;
     }
  
`