import React from 'react';
import { Background } from './styles';

interface Props {
    name: string;
    lat: number;
    lng: number;
    onClick?: (lat: number, lng: number) => void;
}
const Pointer: React.FC<Props> = ({ name, onClick, lat, lng }) => {
    return (
        <Background  >
            <div className="pin" onClick={() => onClick && onClick(lat, lng)}>

            </div>
        </Background>
    );
};

export default Pointer;