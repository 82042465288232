import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  ChangeEvent,
} from "react";
import { Container, Titulo, Formulario, Grid, Col, Row } from "./styles";
import { FormHandles } from "@unform/core";
import { toast } from "react-toastify";
import Select, { SelectType, SelectDefault } from "../../components/Select";

import Table from "../../components/Table";
import Pagination from "../../components/Pagination";
import Novo from "../../components/Novo";
import Input from "../../components/Input";
import Button from "../../components/Button";
import api from "../../services/api";
import Modal from "../../components/Modal";
import Dialog from "../../components/Dialog";

import getValidationsErrors from "../../utils/getValidationsErrors";
import useDebounce from "../../utils/useDebounce";

import { Contrato, ContratoReturn } from "../../model/contrato";

import schema from "../../middlewares/contrato";
import {
  Transportadora,
  TransportadoraReturn,
} from "../../model/Transportadora";
import { Contratante, ContratanteReturn } from "../../model/contratante";

interface Props {
  ocultarTitulo?: boolean;
  transportadora_id?: number;
  contratante_id?: number;
}

const Contratos: React.FC<Props> = ({
  ocultarTitulo,
  transportadora_id,
  contratante_id,
}) => {
  let formRef = useRef<FormHandles>(null);

  const [edit, setEdit] = useState<any>({});
  const [data, setData] = useState<Contrato[]>([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(13);

  const [showModal, setShowModal] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  //Paginação
  const [selectedOption, setSelectedOption] = useState<string>("Ativo");
  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentDatas = data;
  const [count, setCount] = useState<number>(0);
  const [transportadoras, setTransportadoras] = useState<Transportadora[]>([]);
  const [
    selectedTransportadora,
    setSelectedTransportadora,
  ] = useState<SelectType>(SelectDefault);
  const [contratantes, setContratantes] = useState<Contratante[]>([]);
  const [selectedContratante, setSelectedContratante] = useState<SelectType>(
    SelectDefault
  );

  const [orderBy, setOrderBy] = useState<string>("id");
  const [tipoOrder, setTipoOrder] = useState<string>("Asc");

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
  const openModal = () => setShowModal(true);

  const [filtro, setFiltro] = useState<Contrato>({} as Contrato);

  const debounce = useDebounce();

  function handleFiltroChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    debounce(
      () => setFiltro({ ...filtro, [name]: `%${value.toUpperCase()}%` }),
      1000
    );
  }

  let head = {};
  if (contratante_id) {
    head = {
      id: "ID",
      data_inicio: "Data Inicio Contrato",
      data_fim: "Data Fim Contrato",
      descricaoTransportadora: "Transportadora",
      ativo: "Ativo",
    };
  }
  if (transportadora_id) {
    head = {
      id: "ID",
      data_inicio: "Data Inicio Contrato",
      data_fim: "Data Fim Contrato",
      descricaoContratante: "Contratante",
      ativo: "Ativo",
    };
  }

  useEffect(() => {
    const findTransportadora = async () => {
      const response = await api.get<TransportadoraReturn>("/transportadoras");
      if (response.data.itens) {
        setTransportadoras(response.data?.itens);
      } else {
        setTransportadoras([]);
      }
    };
    findTransportadora();
  }, []);

  useEffect(() => {
    const findContratante = async () => {
      const response = await api.get<ContratanteReturn>("/contratantes");
      if (response.data.itens) {
        setContratantes(response.data.itens);
      } else {
        setContratantes([]);
      }
    };
    findContratante();
  }, []);

  useEffect(() => {
    Pesquisar();
  }, [dataPerPage, currentPage, orderBy, tipoOrder, selectedOption, filtro]);

  const Pesquisar = async () => {
    setLoading(true);

    

    const res = await api.get<ContratoReturn>("/contratos", {
      params: {
        page: currentPage,
        size: dataPerPage,
        orderBy: orderBy,
        tipoOrder: tipoOrder,
        ativo: selectedOption === "Ativo" ? true : false,
        transportadora_id,
        contratante_id,
        filtro: {
          ...filtro,
        },
      },
    });

    if (res.data && res.data.itens) {
      const itens = res.data.itens.map(p => ({
          ...p,
          descricaoTransportadora: p.transportadora.razaosocial,
          descricaoContratante: p.contratante.razaosocial,
      }))
      setData(itens);
      setCount(Number(res.data.count));
    } else {
      setData([]);
    }
    setLoading(false);
    setShowModal(false);
  };

  async function handleEdit(data: any) {
    setTimeout(() => {
      formRef.current?.setData(data);
    }, 200);

    setEdit(data);
    setSelectedContratante({ value: data.contratante_id, label: data.contratante.razaosocial });
    setSelectedTransportadora({ value: data.transportadora_id, label: data.transportadora.razaosocial })
    setShowModal(true);
  }

  function handleDialog(data: any) {
    setShowDialog(true);
    setEdit(data);
  }

  async function handleInative() {
    try {
      const response = await api.put(`/contratos/${Number(edit.id)}`, {
        ...data,
        ativo: false,
      });

      if (response.status === 200 || response.status === 204) {
        toast.success("Contrato Inativado com sucesso!");
        setEdit({});
        setShowDialog(false);
      }
    } catch (err) {
      toast.error("Falha ao inativar o registro!");
      setEdit({});
      setShowDialog(false);
    }
  }

  const handleSelectedTransportadora = useCallback(
    async (value: any, actionMeta: any) => {
      if (value) {
        setSelectedTransportadora(value);
      } else {
        setSelectedTransportadora(SelectDefault);
      }
    },
    []
  );

  const handleSelectedContratante = useCallback(
    async (value: any, actionMeta: any) => {
      if (value) {
        setSelectedContratante(value);
      } else {
        setSelectedContratante(SelectDefault);
      }
    },
    []
  );

  async function handleSubmit(data: Contrato) {
    try {
      formRef.current?.setErrors({});


      await schema.validate(data, {
        abortEarly: false,
      });

      let dataSend = {
        ...data,
      };
      if (transportadora_id) {
        dataSend = {
          ...dataSend,
          transportadora_id,
        };
      }

      if (contratante_id) {
        dataSend = {
          ...dataSend,
          contratante_id,
        };
      }

      if (Number(edit.id) > 0) {
        const response = await api.put(`/contratos/${Number(edit.id)}`, {
          ...dataSend,
        });

        if (response.status == 200 || response.status == 204) {
          toast.success("Contrato Alterado com sucesso!");
          setEdit({});
          Pesquisar();
        }
      } else {
        const response = await api.post("/contratos", {
          ...dataSend,
          ativo: true,
        });

        if (response.status == 200 || response.status == 204) {
          toast.success("Contrato Criado com sucesso!");
          setEdit({});
          Pesquisar();
        }
      }
    } catch (err) {
      if (err.toString().includes("ValidationError")) {
        const errors = getValidationsErrors(err);
        formRef.current?.setErrors(errors);
      } else {
        toast.error("Falha ao gravar, verifique os valores informados");
      }
    }
  }

  function handleTipoOrder() {
    setTipoOrder(tipoOrder == "Asc" ? "Desc" : "Asc");
  }

  function handleShowModal(value: boolean) {
    setShowModal(value);
    setEdit({});
    setSelectedTransportadora(SelectDefault);
    setSelectedContratante(SelectDefault);
  }

  return (
    <Container>
      {!ocultarTitulo && (
        <Titulo>
          <h2>Contratos</h2>
        </Titulo>
      )}

      <Novo
        openModal={openModal}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        data={data}
        head={head}
        title="Contratos"
      />

      <Dialog
        titulo="Confirma a inativação do registro?"
        showModal={showDialog}
        setShowDialog={setShowDialog}
        execute={handleInative}
      >
        <h2>ID: {edit.id} </h2>
        <h2>Descrição: {edit.nome}</h2>
      </Dialog>

      <Modal
        titulo="Cadastro de Contrato"
        showModal={showModal}
        setShowModal={setShowModal}
        handleShowModal={handleShowModal}
      >
        <Formulario ref={formRef} onSubmit={handleSubmit}>
          <Grid>
            <Row>
              <Col size={1}>
                <Input
                  name="data_inicio"
                  type="date"
                  placeholder="Data início vigência contrato"
                />
              </Col>
              <Col size={1}>
                <Input
                  name="data_fim"
                  type="date"
                  placeholder="Data fim vigência contrato"
                />
              </Col>
            </Row>
            <Row>
              {contratante_id && (
                <Col size={2}>
                  <Select
                    options={transportadoras.map((p) => ({
                      value: p.id,
                      label: p.razaosocial,
                    }))}
                    placeHolder="Selecione uma Transportadora"
                    handleSelected={handleSelectedTransportadora}
                    selectedValue={selectedTransportadora}
                    name="transportadora_id"
                  />
                </Col>
              )}
              {transportadora_id && (
                <Col size={2}>
                  <Select
                    options={contratantes.map((p) => ({
                      value: p.id,
                      label: p.razaosocial,
                    }))}
                    placeHolder="Selecione uma Contratante"
                    handleSelected={handleSelectedContratante}
                    selectedValue={selectedContratante}
                    name="contratante_id"
                  />
                </Col>
              )}
            </Row>
          </Grid>

          <Button type="submit">Salvar</Button>
        </Formulario>
      </Modal>

      <Table
        data={currentDatas}
        head={head}
        numeroTela="13"
        loading={loading}
        edit={handleEdit}
        remove={handleDialog}
        setOrderBy={setOrderBy}
        handleTipoOrder={handleTipoOrder}
        handleInputChange={handleFiltroChange}
      />

      <Pagination
        postsPerPage={dataPerPage}
        totalPosts={count}
        paginate={paginate}
      />
    </Container>
  );
};

export default Contratos;
