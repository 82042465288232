import { useState } from 'react';

export default function useDebounce() {
  const [typingTimeout, setTypingtimeout] = useState<number>(0);

  function debounce(func: Function , wait: number = 1000 ) {
    clearTimeout(typingTimeout);
    const timeout = setTimeout(() => func() , wait);
    setTypingtimeout(Number(timeout));
  }

  return debounce;
}



