import styled from 'styled-components';
import signInBack from '../../assets/sign-in-background.png';
import { shade } from 'polished';
import { Form } from '@unform/web';

export const Container = styled.div`
    width: 100%;
    height: 80vh;
    
    color: ${props => props.theme.colors.text};
    display: flex;
    flex-direction: column;
    
`;

export const Titulo = styled.div`
    width: 100%;
    height: 40px;
   

    h1, h2, h3 {
        color: ${props => props.theme.colors.text};
        font-weight: 700;
        margin-bottom: 12px;
    }
`

export const Formulario = styled(Form) `

`

export const Group = styled.div`
    justify-content: space-between;
    display: flex;
    width: 100%;
    flex-direction: row;
`

interface props {
    size: number;
}

export const Col = styled.div<props>`
    padding : 4px;
    flex: ${(props) => props.size};   
`;

export const Row = styled.div`
    display: flex;
    @media (max-width: 1082px) {
        display: block;
    }

    
`;

export const Grid = styled.div`
    
`;
