import styled from 'styled-components';
import { Form } from '@unform/web';

export const Container = styled.div`
    width: 100%;
    height: 80vh;
    margin-top: 22px;
    color: ${props => props.theme.colors.text};
    display: flex;
    flex-direction: column;
    
`;

export const Titulo = styled.div`
    width: 100%;
    height: 40px;
   

    h1, h2, h3 {
        color: ${props => props.theme.colors.text};
        font-weight: 700;
        margin-bottom: 12px;
    }
`

export const Formulario = styled(Form) `
    /* display: flex;
    flex-wrap: wrap; */
`


interface props {
    size: number;
    collapse?: (styles: any) => string;
}


const media = {
    xs: (styles: any) => `
        @media only screen and (max-widht: 480px) {
            ${styles}
        }
    `,

    xl: (styles: any) => `
    @media only screen and (max-widht: 480px) {
        ${styles}
    }
    `,

    xh: (styles: any) => `
    @media only screen and (max-widht: 480px) {
        ${styles}
    }
    `,
}

export const Col = styled.div<props>`
    padding : 4px;
    flex: ${(props) => props.size};

    ${(props) => props.collapse && media['xs'](`
        display: none;
    `)};
`

export const Row = styled.div`
    display: flex;
`

export const Grid = styled.div`

`

export const Group = styled.div`
    justify-content: space-between;
    display: flex;
    width: 100%;
    flex-direction: row;

    font-size: 15px;
`
