import React, { useState, useEffect, useRef, useCallback, ChangeEvent } from 'react';
import { Container, Titulo, Formulario, Group, Row, Grid, Col } from './styles';
import { FormHandles } from '@unform/core';
import { toast } from 'react-toastify';

import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import Novo from '../../components/Novo';
import Input from '../../components/Input';
import Button from '../../components/Button';
import api from '../../services/api';
import Modal from '../../components/Modal';
import Dialog from '../../components/Dialog';
import DataInput from '../../components/DataInput';
import Select, { SelectType, SelectDefault } from '../../components/Select';

import getValidationsErrors from '../../utils/getValidationsErrors';
import useDebounce from '../../utils/useDebounce';

import { Motorista, MotoristaReturn } from '../../model/motorista';
import { Transportadora, TransportadoraReturn } from '../../model/Transportadora';

import schema from '../../middlewares/motorista';

const Motoristas: React.FC = () => {
  let formRef = useRef<FormHandles>(null);

  const [edit, setEdit] = useState<any>({});
  const [data, setData] = useState<Motorista[]>([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(13);

  const [transportadoras, setTransportadoras] = useState<Transportadora[]>([]);
  const [selectedTransportadoras, setSelectedTransportadoras] = useState<SelectType>(SelectDefault);

  const [selectedOption, setSelectedOption] = useState<string>('Ativo');
  const [showModal, setShowModal] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  //Paginação 
  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentDatas = data;
  const [count, setCount] = useState<number>(0);

  const [orderBy, setOrderBy] = useState<string>('id');
  const [tipoOrder, setTipoOrder] = useState<string>('Asc');

  const [dataSelecionada, setDataSelecionada] = useState<Date>({} as Date);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber)
  const openModal = () => setShowModal(true);

  const [filtro, setFiltro] = useState<Motorista>({} as Motorista);

  const debounce = useDebounce();

  function handleFiltroChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    debounce(() => setFiltro({ ...filtro, [name]: `%${value.toUpperCase()}%` }), 1000)
  }

  const head = {
    id: "ID",
    apelido: "Apelido",
    data_admissao: "Data Admissão",
    numero_cnh: "Número CNH",
    vencimento_cnh: "Vencimento CNH",
    categoria_cnh: "Categoria CNH",
    ativo: "Ativo",
  };


  useEffect(() => {
    Pesquisar();
  }, [dataPerPage, currentPage, orderBy, tipoOrder, selectedOption, filtro])

  const Pesquisar = async () => {
    setLoading(true);
    const res = await api.get<MotoristaReturn>('/motoristas', {
      params: {
        page: currentPage,
        size: dataPerPage,
        orderBy: orderBy,
        tipoOrder: tipoOrder,
        ativo: selectedOption == 'Ativo' ? 1 : 0,
        filtro: { ...filtro }
      }
    });

    if (res.data && res.data.itens) {
      setData(res.data.itens);
      setCount(Number(res.data.count));
    } else {
      setData([]);
    }
    setLoading(false);
    setShowModal(false);
  }

  useEffect(() => {
    const PesquisarMarcas = async () => {
      const res = await api.get<TransportadoraReturn>('/transportadoras', {
        params: {
          filtro: {}
        }
      });

      if (res.data && res.data.itens) {
        setTransportadoras(res.data.itens);
      } else {
        setTransportadoras([]);
      }
    }
    PesquisarMarcas();
  }, [])

  async function handleEdit(data: any) {

    setTimeout(() => {
      formRef.current?.setData(data)
    }, 200);

    // setSelectedTipoAgrupamento(data.tipoagrupamento);
    setEdit(data);

    setShowModal(true);
  };

  function handleDialog(data: any) {
    setShowDialog(true);
    setEdit(data);
  }

  async function handleInative() {
    try {
      const response = await api.put(`/motoristas/${Number(edit.id)}`, {
        ...data, ativo: false
      })

      if (response.status == 200 || response.status == 204) {
        toast.success('Motorista inativado com sucesso!');
        setEdit({});
        setShowDialog(false);
      }
    } catch (err) {
      toast.error('Falha ao inativar o registro!');
      setEdit({});
      setShowDialog(false);
    }
  }

  async function handleSubmit(data: Motorista) {
    try {
      formRef.current?.setErrors({});

      await schema.validate(data, {
        abortEarly: false
      });

      if (Number(edit.id) > 0) {

        const response = await api.put(`/motoristas/${Number(edit.id)}`, {
          ...data
        })

        if (response.status == 200 || response.status == 204) {
          toast.success('Motorista Alterado com sucesso!');
          setEdit({});
        }

      } else {
        const response = await api.post("/motoristas", {
          ...data, ativo: true
        })

        if (response.status == 200 || response.status == 204) {
          toast.success('Motorista criado com sucesso!');
          setEdit({});
        }
      }
    }
    catch (err) {
      if (err.toString().includes('ValidationError')) {
        const errors = getValidationsErrors(err);
        formRef.current?.setErrors(errors);
      } else {
        toast.error('Falha ao gravar, verifique os valores informados');
      }
    }
  };

  function handleDataChange(event: Date) {
    setDataSelecionada(event);
  }

  const handleSelectedTransportadora = useCallback(async (value: any, actionMeta: any) => {
    if (value) {
      setSelectedTransportadoras(value);
    } else {
      setSelectedTransportadoras(SelectDefault);
    }
  }, [])

  function handleTipoOrder() { setTipoOrder(tipoOrder == 'Asc' ? 'Desc' : 'Asc') };

  const options: any[] = [];

  transportadoras.forEach(p => {
    options.push({ value: p.id, label: p.razaosocial });
  });

  function handleShowModal(value: boolean) {
    setShowModal(value);
    setSelectedTransportadoras(SelectDefault);
    setEdit({});
  }

  return (
    <Container>

      <Titulo>
        <h2>Motoristas</h2>
      </Titulo>

      <Novo
        openModal={openModal}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        data={data}
        head={head}
        title="Motoristas"
      />

      <Dialog titulo="Confirma a inativação do registro?"
        showModal={showDialog}
        setShowDialog={setShowDialog}
        execute={handleInative}
      >
        <h2>Razão Social: {edit.razaosocial} </h2>
        <h2>Nome Fantasia: {edit.nomefantasia}</h2>

      </Dialog>

      <Modal titulo="Cadastro de Motorista" showModal={showModal} setShowModal={setShowModal} handleShowModal={handleShowModal}>

        <Formulario ref={formRef} onSubmit={handleSubmit}  >
          <Grid>
            <Row>
              <Col size={1} >
                <Select
                  options={options}
                  placeHolder="Selecione a Pessoa"
                  handleSelected={handleSelectedTransportadora}
                  selectedValue={selectedTransportadoras}
                  name="transportadoras"
                />
              </Col>
            </Row>
            <Row>
              <Col size={1}>
                <DataInput handleDataChange={handleDataChange} dataSelecionada={dataSelecionada} setDataSelecionada={setDataSelecionada} />
              </Col>
              <Col size={1}>
                <Input name="apelido" id="apelido" placeholder="Apelido" />
              </Col>
              <Col size={1}>
                <Input name="numerocnh" placeholder="Número CNH" />
              </Col>
              <Col size={1} >
                <Input name="vencimentocnh" placeholder="Vencimento CNH" />
              </Col>
              <Col size={1} >
                <Input name="categoriacnh" placeholder="Categoria CNH" />
              </Col>
            </Row>
            <Row>
              <Col size={1} >
                <Select
                  options={options}
                  placeHolder="Selecione a Transportadora"
                  handleSelected={handleSelectedTransportadora}
                  selectedValue={selectedTransportadoras}
                  name="transportadoras"
                />
              </Col>
            </Row>
          </Grid>


          <Button type="submit">Salvar</Button>
        </Formulario>
      </Modal>

      <Table
        data={currentDatas}
        numeroTela="20"
        head={head}
        loading={loading}
        edit={handleEdit}
        remove={handleDialog}
        setOrderBy={setOrderBy}
        handleTipoOrder={handleTipoOrder}
        handleInputChange={handleFiltroChange}
      />

      <Pagination
        postsPerPage={dataPerPage}
        totalPosts={count}
        paginate={paginate}
      />
    </Container>
  )
}

export default Motoristas;